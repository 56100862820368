import { Request } from "utils";
import _ from 'lodash';
import { GET_METRO_LIST_DATA_REQUEST_INIT, GET_METRO_LIST_DATA_REQUEST_FAILURE, GET_METRO_LIST_DATA_REQUEST_DONE, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE, GET_METRO_LIST_INIT_DATA_REQUEST_INIT, GET_METRO_LIST_INIT_DATA_REQUEST_DONE, GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE } from "./../constants";
import moment from "moment";

export const fnMetroActions = {
    getListPageInitData(data = {}) { 
        return dispatch => {
            dispatch({ type: GET_METRO_LIST_INIT_DATA_REQUEST_INIT });
            return Request.post('getRailListPageInitData', data).then(res => {
                dispatch({ type: GET_METRO_LIST_INIT_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({ type: GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err.toString());
            });
        }
    },
    getAllMetroProjectsList(data) { 
        return dispatch => {
            dispatch({ type: GET_METRO_LIST_DATA_REQUEST_INIT });
            return Request.post('getAllMetroProjectsList', data).then(res => {
                dispatch({ type: GET_METRO_LIST_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({ type: GET_METRO_LIST_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err.toString());
            });
        }
    },
    downloadExcelFileData(params = {}, filename = '') { 
        return (dispatch) => {
            dispatch({ type: DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT });            
            return Request.downloadFiles({
                requestFor: 'exportMetroProjectsListInExcel',
                data: params,
                filename: filename || `metro_data_${moment().format('DD_MMM_YYYY')}_${moment.now()}.xls`
            }).then(res => {
                dispatch({ type: DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE });
            }).catch(err => {
                dispatch({ type: DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE, payload: err.toString() });
            });
        }
    }
};