import React, {Fragment, useState, Component} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';


const PresentationLayout = props => {
    const { children } = props;

        return (
            <Fragment>
                {children}
            </Fragment>
        );
}

PresentationLayout.propTypes = {
        children: PropTypes.node
}

export default PresentationLayout;
