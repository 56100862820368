import React, {Fragment, useState, Component} from 'react';
import cx from 'classnames';
import _ from 'lodash';

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import {SidebarHeader, SidebarMenu, SidebarFooter} from '../../layout-components'
import { fnLogoutAuthentication } from 'actions';

class Sidebar extends Component {

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    logout() {
        this.props.logout();
    }

    render() {
        let {
            sidebarShadow,
            sidebarStyle,
            sidebarFooter,
            sidebarToggleMobile
        } = this.props;

        return (
            <Fragment>
                <div className={cx(
                    "app-sidebar", sidebarStyle,
                {'app-sidebar--shadow': sidebarShadow}
                    )}>
                    <SidebarHeader/>
                    <div className="app-sidebar--content">
                        <SidebarMenu road_project_count={this.props.count} road_qry_count={this.props.qry_count}    my_ws_count={this.props.my_ws_count}ws_project_count={this.props.ws_project_count}/>
                    </div>
                    {sidebarFooter && <SidebarFooter logout={this.logout.bind(this)} /> }
                </div>
                <div onClick={this.toggleSidebarMobile} className={cx("app-sidebar-overlay", {'is-active': sidebarToggleMobile})}/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarStyle: state.ThemeOptions.sidebarStyle,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({

    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    logout: () => dispatch( fnLogoutAuthentication() )
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
