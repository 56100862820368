import React, {Fragment, useState, Component} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';


import {connect} from 'react-redux';

import { Header, SidebarCollapsed, ThemeConfigurator } from '../../layout-components'

const CollapsedSidebar = props => {
    const { children, contentBackground, themeConfiguratorToggle, sidebarToggleMobile, headerDrawerToggle, headerSearchHover } = props;

        return (
            <Fragment>
                <div className={cx("app-wrapper app-sidebar-collapsed app-sidebar-fixed app-header-fixed vh-100", contentBackground,
                        {
                        'app-sidebar-mobile-open' : sidebarToggleMobile,
                        'header-drawer-open' : headerDrawerToggle,
                        'search-wrapper-open' : headerSearchHover
                        })}>
                        <SidebarCollapsed />
                    <div className="app-main">
                        <Header/>
                        <div className="app-content">
                            <div className="app-inner-content-layout--main">
                                {children}
                            </div>
                        </div>
                    </div>
                        <ThemeConfigurator/>
                </div>
            </Fragment>
        );
}


CollapsedSidebar.propTypes = {
    children: PropTypes.node
}

const mapStateToProps = state => ({
    contentBackground: state.ThemeOptions.contentBackground,
    themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
    headerSearchHover: state.ThemeOptions.headerSearchHover

});

export default connect(mapStateToProps)(CollapsedSidebar);
