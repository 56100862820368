import React, { Fragment } from 'react';
import { Table, Form, FormGroup, Label, Col, CustomInput, Input, UncontrolledTooltip, Button, Alert } from 'reactstrap';
import { DateRangePicker, ReactDateTime } from 'components/common';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropagateLoader } from 'react-spinners';

function ValidityPeriodListForm(props) {

    return (
        <Fragment>
            <SubscriptionPeriodForm {...props} />
            <SubscriptionPeriodLists {...props} />
        </Fragment>
    );
}
export default ValidityPeriodListForm;


function SubscriptionPeriodForm(props) {
    const { formData, formError, handleChange, payMethodsOptions, bankOptions, resetSubscriptionForm, userData, handleSubmit, saveSubscriptionDataProps, clearFormErrorMsg } = props;

    let noOfLoginUsers = false;
    if (userData.is_local_admin && !userData.is_ip_user) { 
        noOfLoginUsers = true;
    }

    let isApplySmDevice = false;
    if (window.outerWidth >= 1200 && window.outerWidth <= 1440) { 
        isApplySmDevice = true;
    }

    let isValidStartDt = _.has(formError, 'start_date') ? formError.start_date : false;
    let isValidEndDt = _.has(formError, 'end_date') ? formError.end_date : false;
        

    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container">
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{marginTop: '5px'}}>Subscription Period</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-3 page-drawer-body-container">
                <Form onSubmit={handleSubmit} className="form-customise">
                    <FormGroup row>
                        <Col sm={noOfLoginUsers ? 5 : 8} className="date-range xs">
                            <Label>Subscription Period</Label>
                            {/****<DateRangePicker
                                fromDate={formData.start_date || ''}
                                //formError={formError || {}}
                                toDate={formData.end_date || ''}
                                isValidFrom={formError.start_date || false}
                                isValidTo={formError.end_date || false}
                                handleChange={handleChange}
                            />****/}
                            <ReactDateTime.DateRangeField id="validity_period" dateFormat={"DD-MMM-YYYY"} viewMode="days" key="date_range_validity" 
                                inputProps={{ placeholder: 'DD-MMM-YYYY', readOnly: true, className: (isValidStartDt || isValidEndDt) ? 'is-invalid': '' }}
                                handleChange={handleChange}
                                startDate={_.has(formData, 'validity_period.start_date') ? moment.unix(formData.validity_period.start_date) : ''}
                                endDate={_.has(formData, 'validity_period.end_date') ? moment.unix(formData.validity_period.end_date) : ''}
                                closeOnSelect={true}
                                timeFormat={false}
                            />

                        </Col>

                        {noOfLoginUsers ? (
                            <Col sm={3}>
                                <Label>No Of Login Users</Label>
                                <Input className="form-control-sm" invalid={formError.no_of_login_user} value={formData.no_of_login_user || ''} onChange={e => handleChange(e.target.value, 'no_of_login_user') } placeholder="No Of Login"/>
                            </Col> 
                        ) : null }
                                               
                        <Col sm={2}>
                            <Label>&nbsp;</Label>
                            <CustomInput
                                type="checkbox"
                                //invalid={formError.is_subscription}
                                id="is_subscription"
                                name="is_subscription"
                                label="Subscription"
                                checked={formData.is_trial === 0}
                                onChange={(e) => handleChange(0, 'is_trial')}
                            />
                        </Col>
                        <Col sm={2}>
                            <Label>&nbsp;</Label>
                            <CustomInput
                                type="checkbox"
                                //invalid={formError.is_trial}
                                id="is_trial"
                                name="is_trial"
                                label="Trial"
                                checked={formData.is_trial === 1}
                                onChange={(e) => handleChange(1, 'is_trial')}
                            />
                        </Col>                       
                    </FormGroup>
                    {/**formData.is_trial === 0 ? (
                        <Fragment>
                            <FormGroup row>
                                <Col sm={4}>
                                    <Label>Bank Name</Label>
                                    <Input type="select" bsSize="sm" name="id_m_bank" onChange={e=>handleChange(e)}>
                                        <option value="">No Data Found</option>
                                    </Input>
                                </Col>
                                <Col sm={4}>
                                    <Label>Date</Label>
                                    <DateInputPicker placeholder="DD-MMM-YYYY" name="payment_date" selected={formData.payment_date} handleChange={handleChange} />
                                </Col>
                                <Col sm={4}>
                                    <Label>Amount</Label>
                                    <Input className="form-control-sm" value={formData.paid_amount || ''} onChange={e => handleChange(e.target.value, 'paid_amount') } placeholder="Enter Amount"/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={4}>
                                    <Label>Payment Method</Label>
                                    <Input type="select" bsSize="sm" name="id_payment_method" onChange={e=>handleChange(e)}>
                                        <option value="">No Data Found</option>
                                    </Input>
                                </Col>
                                <Col sm={4}>
                                    <Label>Ref No</Label>
                                    <Input className="form-control-sm" placeholder="Reference No" value={formData.payment_ref_no || ''} onChange={e => handleChange(e.target.value, 'payment_ref_no') } />
                                </Col>
                            </FormGroup>
                        </Fragment>
                    ): null**/}
                    <FormGroup>
                        <CustomInput className="mt-4" type="checkbox" id="is_mail_send_to_client" checked={formData.is_mail_send_to_client===1} onChange={e=>handleChange((formData.is_mail_send_to_client === 0 ? 1 : 0), 'is_mail_send_to_client')} label="E-Mail Send To Client"/>
                    </FormGroup>
                    {formError.msg ? (
                        <FormGroup>
                            <Alert color="danger" isOpen={true} toggle={clearFormErrorMsg}>{formError.msg}</Alert>
                        </FormGroup>
                    ) : null }
                    <FormGroup row className="mt-2 text-center">
                        {saveSubscriptionDataProps.loading ? (
                            <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <>
                                {isApplySmDevice ? <><Col>&nbsp;</Col><Col>&nbsp;</Col></> : null}        
                                <Col> <Button type="reset" className="form-reset-cancel-btn" color="warning" outline block onClick={e => {resetSubscriptionForm()}}>Cancel</Button> </Col>
                                <Col> <Button type="submit" className="form-submit-btn" color="primary" block> Save </Button> </Col>
                            </>
                        )}
                    </FormGroup>
                </Form>
            </div>    
        </Fragment>
    );
}

function SubscriptionPeriodLists(props) {
    const { userData, getSubscriptionDataById, deleteSubscriptionDataById } = props;

    const { subscriptionPeriods, profile } = userData;
    
    let tblHeader = null;
    if (userData.is_local_admin === 't') { 
        tblHeader = (<th>No.Of Login</th>);
    }

    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container">
                <div className="text-left">
                    <h6 className="mb-0 text-black">Subscription Period List</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-3 page-drawer-body-container">
                <Table className="table table-bordered table-condensed table-hover fz10 table-custom">
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center" width="10%">#</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            {tblHeader}
                            <th>Subscription</th>
                            {/***<th>Payment Detail</th>
                            <th>Bank</th>
                            <th>Paid Date</th>
                            <th>Paid Amount</th>
                            <th>Paid Mode</th>
                            <th>Ref No</th>***/}
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!_.isEmpty(subscriptionPeriods) ?
                            subscriptionPeriods.map((row, indx) => {
                                let isTrial = !_.isNil(row.is_trial) && !_.isEmpty(row.is_trial) ? (row.is_trial === 't' ? true : false) : null;

                                let payment_details = [];
                                if (!isTrial) {
                                    if (!_.isEmpty(row.bank_name) && !_.isNil(row.bank_name)) { payment_details.push('Bank: '+row.bank_name); }
                                    if (!_.isEmpty(row.payment_date) && !_.isNil(row.payment_date)) { payment_details.push('Date: '+row.payment_date); }
                                    if (!_.isEmpty(row.paid_amount) && !_.isNil(row.paid_amount)) { payment_details.push('Amount: '+row.paid_amount); }
                                    if (!_.isEmpty(row.payment_ref_no) && !_.isNil(row.paid_amount)) { payment_details.push('Ref #: '+row.payment_ref_no); }
                                }
                                let payment_details_data = payment_details.length > 0 ? _.join(payment_details, ',<br/>'): '-';


                                return (
                                    <tr key={indx + 1}>
                                        <td className="text-center">{indx + 1}</td>
                                        <td>{!_.isNil(row.start_date) ? moment(row.start_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') : null}</td>
                                        <td>{!_.isNil(row.end_date) ? moment(row.end_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') : null}</td>
                                        {tblHeader ? (<td> {row.no_of_login_user} </td>) : null}
                                        <td>{isTrial ? 'Trial' : 'Paid'}</td>
                                        {/***<td>{payment_details_data}</td>***/}
                                        <td className="text-center">
                                            <a href="" onClick={e => { e.preventDefault(); getSubscriptionDataById(row.id_login_subscription_period); }} className="btn btn-sm btn-outline-primary px-2 mx-3 border-1" id={'edit_usr_btn_'+ row.id_login_subscription_period} style={{fontSize: '0.63125rem'}}>
                                                <FontAwesomeIcon icon={['fa', 'edit']}/>    
                                            </a>
                                            <UncontrolledTooltip trigger="hover" popperClassName={'tooltip-primary'} placement="top" container="body" target={'edit_usr_btn_'+ row.id_login_subscription_period}>  {'Edit Subscription'}
                                            </UncontrolledTooltip>
                                            <a href="" onClick={e => { e.preventDefault(); deleteSubscriptionDataById(row.id_login_subscription_period, !_.isNil(row.id_user_subscription_payment)? row.id_user_subscription_payment: 0); }} className="btn btn-sm btn-outline-danger px-2 border-1" id={'delete_usr_btn_'+ row.id_login_subscription_period} style={{fontSize: '0.63125rem'}}>
                                                <FontAwesomeIcon icon={['fa', 'trash-alt']}/>
                                            </a>  
                                            <UncontrolledTooltip trigger="hover" popperClassName={'tooltip-danger'} placement="top" container="body" target={'delete_usr_btn_'+ row.id_login_subscription_period}>  {'Delete Subscription'}
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                );
                            })
                        : (
                            <tr>
                                <td colSpan={tblHeader ? 7 : 6}>No Data Found!...</td>
                            </tr>
                        )}                        
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
}