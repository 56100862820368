import React, { Fragment } from 'react';
import cx from 'classnames';
import { Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { DataTables } from 'components';

const UsersTabLists = (props) => {

    const { activeTab, setActiveTab } = props;
    return (
        <Fragment>
            <Row>
                <Col xl="12">
                    <Card className="card-box mb-5 rounded-0">
                        <div className="tabs-animated tabs-animated-line">
                            <Nav tabs justified>
                                <NavItem>
                                    <NavLink className={cx({ active: activeTab === '1' })} onClick={() => setActiveTab('1')}> <span>Subscriped Users ( {props.subscriptionUsersCount || 0} )</span>  </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={cx({ active: activeTab === '2' })} onClick={() => setActiveTab('2')}>
                                        <span>Trial Users ( { props.trailUsersCount || 0 } )</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className="mb-0 p-3">                                
                                {activeTab == '1'? (<DataTables {...props.tablePropOptions}/> ): null}
                            </TabPane>
                             <TabPane tabId="2" className="mb-0 p-3">
                                {activeTab == '2'? ( <DataTables {...props.tablePropOptions} />  ): null}
                            </TabPane>
                        </TabContent>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}
export default UsersTabLists;