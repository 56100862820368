import { GET_METRO_LIST_DATA_REQUEST_INIT, GET_METRO_LIST_DATA_REQUEST_DONE, GET_METRO_LIST_DATA_REQUEST_FAILURE, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE, GET_METRO_LIST_INIT_DATA_REQUEST_INIT, GET_METRO_LIST_INIT_DATA_REQUEST_DONE, GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE } from "./../constants";

const initState = {
    loading: false,
    error: false,
    msg: '',
    data: {},
    downloadExcel: {
        loading: false,
        error: false,
        msg: ''
    },
    listInitData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    }
};

export const Metro = (state = initState, action) => {
    switch (action.type) {
        case GET_METRO_LIST_DATA_REQUEST_INIT:
            return Object.assign({}, state, { loading: true, error: false, data: {} });        
        case GET_METRO_LIST_DATA_REQUEST_DONE:
            return Object.assign({}, state, { loading: false, error: false, data: action.payload });        
        case GET_METRO_LIST_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { loading: false, error: true, msg: action.payload });
        
        case DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { downloadExcel: { loading: true, error: false, msg: '' } });
        case DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { downloadExcel: { loading: false, error: false, msg: '' } });
        case DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { downloadExcel: { loading: false, error: true, msg: action.payload } });
        
        case GET_METRO_LIST_INIT_DATA_REQUEST_INIT:
            return Object.assign({}, state, { listInitData: { loading: true, error: false, msg: '', data: {} } });
        case GET_METRO_LIST_INIT_DATA_REQUEST_DONE:
            return Object.assign({}, state, { listInitData: { loading: false, error: false, msg: '', data: action.payload } });
        case GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { listInitData: { loading: false, error: true, msg: action.payload, data: {} } });
        
    }
    return state;
}