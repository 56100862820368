import React, {Fragment,  Component} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { UncontrolledTooltip, Button } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { cmnFnTogglePageDrawer } from 'actions/commoncross.actions';

class SlideDrawer extends Component {

    constructor(props) {
        super(props);
    }


    /****toggleSidebarMobile = () => {
        let {pageDrawerToggle, setPageDrawerToggle} = this.props;
        setPageDrawerToggle(!pageDrawerToggle);
    }***/

    backdropCloseEvent = () => {
        if (!_.isNil(this.props.backdrop) && this.props.backdrop == true) {
            this.toggleSidebarMobile();
        }
    }

    render() {
        
        let { pageDrawerToggle, children, closeSidebarMobile, contentWidth, size } = this.props;
       

        let defaultStyle = {};
        if (contentWidth > 0) {
            defaultStyle = {
                width: (contentWidth / 100) * 92 + 'px'
            };
        }

        return (
            <Fragment>
                <div className={cx("page-drawer-content", {"bg-light-blue": this.props.bgBlue === true} , size)} style={defaultStyle}>
                    <Button onClick={closeSidebarMobile} className="close-page-drawer-btn btn btn-sm" id="ClosePageDrawerTooltip">
                        <div className={cx("navbar-toggler hamburger hamburger--elastic", {'is-active': pageDrawerToggle})}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                        </div>
                    </Button>
                    <UncontrolledTooltip target="ClosePageDrawerTooltip" placement="left">
                        Close drawer
                    </UncontrolledTooltip>
                    <div className="vh-100 shadow-overflow">
                        <PerfectScrollbar option={{ wheelPropagation: false }} style={{ overflowY: 'auto', paddingBottom: '100px'}}>
                            {children}
                        </PerfectScrollbar>
                    </div>
                </div>
                <div onClick={this.backdropCloseEvent} className={cx("page-drawer-overlay page-content", { 'is-active': pageDrawerToggle }, {'bgClickable': this.props.bgClickable === true})}/>
            </Fragment>
        );
    }
}

SlideDrawer.propTypes = {
    backdrop: PropTypes.bool,
    size: PropTypes.string,
    contentWidth: PropTypes.number
};

SlideDrawer.defaultProps = {
    backdrop: true,
    size: 'xs', // sm | md | lg
    contentWidth: 0
}

const mapStateToProps = state => ({
    pageDrawerToggle: state.ThemeOptions.pageDrawerToggle
});

const mapDispatchToProps = dispatch => ({
    //setPageDrawerToggle: enable => dispatch(setPageDrawerToggle(enable)),
    setPageDrawerToggle: enable => dispatch( cmnFnTogglePageDrawer(enable) )
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideDrawer);
