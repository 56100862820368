import { Api, ApiRoad, Request } from "utils"
import {
    GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT, GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE,
    GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE, GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT, GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE, GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE, SERVER_BASE_URL, UPLOAD_FINANCE_DATA_REQUEST_INIT, UPLOAD_FINANCE_DATA_REQUEST_DONE, UPLOAD_FINANCE_DATA_REQUEST_FAILURE, SAVE_FINANCE_DATA_REQUEST_INIT, SAVE_FINANCE_DATA_REQUEST_DONE, SAVE_FINANCE_DATA_REQUEST_FAILURE, DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT, DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE, DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE, DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT, DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE, DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE,
    CHECK_CIN_CODE_ISVALID_REQUEST_INIT, CHECK_CIN_CODE_ISVALID_REQUEST_DONE, CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE, GET_COMPANY_LIST_DATA_REQUEST_INIT, GET_COMPANY_LIST_DATA_REQUEST_DONE, GET_COMPANY_LIST_DATA_REQUEST_FAILURE, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE
} from "../constants";
import _ from 'lodash';

export const fnGetFinanceDataBasedOnCompanyId = (m_company_id) => (dispatch) => {
  
    dispatch({ type: GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT });
    
    return Api.post('', { m_company_id }, { params: { 'for': 'getFinanceDataBasedOnCompany' } })
        .then(({ data, ...res }) => {
            dispatch({ type: GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE, payload: data });
            return Promise.resolve(data);
        }).catch((err) => {
            dispatch({ type: GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE, payload: err.toString() });
            return Promise.reject(err);
        });
};

export const fnDownloadExcelFileData = (params, filename) => (dispatch) => {
    dispatch({ type: GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT });
    Request.downloadFiles({
        //url: SERVER_BASE_URL,
        requestFor: params.for,
        data: params,
        filename: filename || 'unknown_excel.xls'
    }).then(() => {
        dispatch({type: GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE});
    }).catch(() => {
        dispatch({type: GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE});
    });
};

export const fnActionCompany = {
    getCompanyListDatails(data) {
        return (dispatch) => {
            dispatch({ type: GET_COMPANY_LIST_DATA_REQUEST_INIT });
            return Request.post('getAllCompanyList', data).then((res) => { 
             
                dispatch({ type: GET_COMPANY_LIST_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve( _.get(res, 'meta.data') );
            }).catch((err) => {
                dispatch({ type: GET_COMPANY_LIST_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },
    getAddCompanyInitDataDetails() { 
        return (dispatch) => {
            dispatch({ type: GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT });
            return Request.post('getAddCompanyInitDataDetails', {}).then((res) => {                
                dispatch({ type: GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE, payload: res });
                return Promise.resolve( _.get(res, 'meta.data') );
            }).catch((err) => {
                dispatch({ type: GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },
    getParentCompanyDataOptions() { 
        return (dispatch) => {
            dispatch({ type: GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT });
            return Request.post('getParentCompanyDataOptions', {}).then((res) => {                
                dispatch({ type: GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve( _.get(res, 'meta.data') );
            }).catch((err) => {
                dispatch({ type: GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },
    checkCINnoIsValid(data) { 
        return (dispatch) => {
            dispatch({ type: CHECK_CIN_CODE_ISVALID_REQUEST_INIT });
            return Request.post('checkCINnoIsValid', data).then((res) => {                
                dispatch({ type: CHECK_CIN_CODE_ISVALID_REQUEST_DONE, payload: res });
                return Promise.resolve( _.get(res, 'meta.data') );
            }).catch((err) => {
                dispatch({ type: CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },

    saveCompanyGroupData(data) {
        return dispatch => { 
            return Request.post('saveCompanyGroupData', data).then(res => {
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                return Promise.reject(err);
            });
        }
    },

    saveCompanyFormData(data) {
        return dispatch => { 
            return Request.post('saveCompanyDetailsData', data).then(res => {
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                return Promise.reject(err);
            });
        }
    },
    deleteCompanyData(data) { 
        return dispatch => { 
            return Request.post('deleteCompanyDetails', data).then(res => {
                return Promise.resolve(_.get(res));
            }).catch(err => {
                return Promise.reject(err);
            });
        }
    },
    uploadFinanceAnnualReportFile(data) { 
        return (dispatch) => {
            dispatch({ type: UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT });
            return Request.uploadFiles('uploadFinanceAnnualReportFile', data).then((res) => {
                dispatch({ type: UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE, payload: res });
                return Promise.resolve(res);
            }).catch((err) => {
                dispatch({ type: UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },
    deleteFinanceAnnualReportFile(data){
        return (dispatch) => { 
            dispatch({type: DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT});
            return Request.post('deleteUploadAnnualReport', data).then(res => {
                dispatch({type: DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },    
    uploadFinanceFile(data) {
        return (dispatch) => {
            dispatch({ type: UPLOAD_FINANCE_DATA_REQUEST_INIT });
            return Request.uploadFiles('uploadCompanyFinanceData', data).then((res) => {
                dispatch({ type: UPLOAD_FINANCE_DATA_REQUEST_DONE, payload: res });
                return Promise.resolve(res);
            }).catch((err) => {
                dispatch({ type: UPLOAD_FINANCE_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err);
            });
        }
    },
    saveFinanceFileData(data) { 
        return (dispatch) => {
            dispatch({ type: SAVE_FINANCE_DATA_REQUEST_INIT });
            return Request.post('saveCompanyFinanceData', data).then(res => { 
                dispatch({ type: SAVE_FINANCE_DATA_REQUEST_DONE });
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({ type: SAVE_FINANCE_DATA_REQUEST_FAILURE, payload: err });
                return Promise.reject(err);
            });
        }
    },
    deleteFinanceSummary(data) { 
        return (dispatch) => { 
            dispatch({type: DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT});
            return Request.post('deleteFinanceSummaryMonYrWise', data).then(res => {
                dispatch({type: DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    deleteAllFinanceData(data) { 
        return (dispatch) => { 
            dispatch({type: DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT});
            return Request.post('deleteCompanyAllFinanceSummary', data).then(res => {
                dispatch({type: DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    }
};