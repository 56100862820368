import React, {Fragment} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

import { Sidebar, Header, ThemeConfigurator } from '../../layout-components'

const LeftSidebar = props => {
    const { children,qry_count,ws_project_count,   my_ws_count ,sidebarToggle, sidebarToggleMobile, sidebarFixed, headerFixed, headerSearchHover, headerDrawerToggle, pageDrawerToggle, footerFixed, contentBackground, themeConfiguratorToggle,count } = props;

    return (
            <Fragment>
                <div

                    className={cx("app-wrapper", contentBackground,
                        {
                        'header-drawer-open' : headerDrawerToggle,
                        'page-drawer-open' : pageDrawerToggle,
                        'app-sidebar-collapsed' : sidebarToggle,
                        'app-sidebar-mobile-open' : sidebarToggleMobile,
                        'app-sidebar-fixed' : sidebarFixed,
                        'app-header-fixed' : headerFixed,
                        'app-footer-fixed' : footerFixed,
                        'search-wrapper-open' : headerSearchHover
                    })}>
                        <div>
                            <Sidebar count={count} qry_count={qry_count} ws_project_count = {ws_project_count}   my_ws_count={my_ws_count} />
                        </div>
                        <div className="app-main">
                            <Header/>
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="app-content--inner__wrapper">
                                        {children}
                                    </div>
                                </div>
                                {/***<Footer/>***/}
                            </div>
                        </div>
                        <ThemeConfigurator/>
                    </div>
            </Fragment>
        );
}

LeftSidebar.propTypes = {
    children: PropTypes.node
}

const mapStateToProps = state => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarFixed: state.ThemeOptions.sidebarFixed,

    headerFixed: state.ThemeOptions.headerFixed,
    headerSearchHover: state.ThemeOptions.headerSearchHover,
    headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

    //Code Added by Tamizh
    pageDrawerToggle: state.ThemeOptions.pageDrawerToggle,

    footerFixed: state.ThemeOptions.footerFixed,

    contentBackground: state.ThemeOptions.contentBackground,
    themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle
});

export default connect(mapStateToProps)(LeftSidebar);
