import React, { useEffect, useRef, useState, Fragment } from 'react';
import _ from 'lodash';
import { Card, CardHeader, Button, CardBody, Row, Col, Progress, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import CountUp from 'react-countup';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

const PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE = 25;

HighchartsMore(Highcharts);

export function RoadDashboardCardContainer(props) {

	const { data, refreshDashboardRoadData, userRights: { DASHBOARD }, downloadRoadExcelFileData, exportRoadData } = props;

	const [isExapandedCard, setExpandedCard] = useState(true);

	const expandToggle = (e) => {
		e.preventDefault();
		if (!isExapandedCard) {
			refreshDashboardRoadData();
		}
		setExpandedCard(!isExapandedCard);
	}

	const resDataExportEvent = (e) => {
		e.preventDefault();
		downloadRoadExcelFileData();
	}


	return (
		<Card className="rounded-0 bg-transparent mb-4">
			<CardHeader className="rounded-0 mb-1 border-bottom d-flex align-items-center justify-content-between dboard-heading">
				<h5 className="display font-weight-bold bg-white mb-0 d-inline-block"><FontAwesomeIcon icon={['fas', 'road']} />&nbsp; Road</h5>

				<div>
					{DASHBOARD.ROAD_DBOARD_EXPORT_BTN ? (
						<Fragment>
							<Button size="sm" color="info" id="export_data_btn" onClick={resDataExportEvent} className="mr-2">
								<span className="btn-wrapper--icon">
									<FontAwesomeIcon icon={['fas', 'file-excel']} className="opacity-8 font-size-xs" />
								</span>
							</Button>
							<UncontrolledTooltip popperClassName={'tooltip-info'} placement="top-end" target="export_data_btn">
								Export Data To Excel
							</UncontrolledTooltip>
						</Fragment>
					) : null}

					{/*** <Button color={isExapandedCard ? "danger" : "primary"} size="sm" id="toggleRoadDboardBtn" className="float-right" onClick={expandToggle} >
                        {isExapandedCard ? (
                            <Fragment>
                                <FontAwesomeIcon icon={['fas', 'compress']} size="sm" />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FontAwesomeIcon icon={['fas', 'expand']} size="sm" />
                            </Fragment>
                        )}
                    </Button>
                    <UncontrolledTooltip trigger="hover" popperClassName={isExapandedCard ? "tooltip-danger" : "tooltip-primary"} target="toggleRoadDboardBtn">
                        {isExapandedCard ? 'Minimize': 'Expand'}
                    </UncontrolledTooltip> ****/}
				</div>
			</CardHeader>
			<CardBody className="p-2" >
				{isExapandedCard ? <RoadDashboardExpandedCard {...props} isExapandedCard={isExapandedCard} /> : <RoadDashboardCardMinimize {...props} isExapandedCard={isExapandedCard} />}
			</CardBody>
		</Card>
	);
}

function ModeandStatusCountAndAmt(props) {
	const history = useHistory()
	const { status_data, status } = props;
	const [showPrice, setShowPrice] = useState(false);
	const [rSelected, setRSelected] = useState(2);

	const expandToggle = (e) => {
		if (e === 1) {
			setRSelected(1)
			setShowPrice(true);
		} else {
			setRSelected(2)
			setShowPrice(false);
		}
	}

	const gotTOProject = (e, key) => {
		let data = e;
		if(key === 'total') {
			data.total = Number(e.total_project);
		} else {
			data.m_mode_id = key;
		}
		data.key = +e.m_project_status_id;
		data.name = e.project_status;
		console.log(data);
		if (e?.total > 0 || data?.m_mode_id) {
			history.push('/rdprojects', data)
		}
	}

	return (
		<>
			<Card className="card-list-box">
				<CardHeader className="rounded-0 mb-1 border-bottom d-flex align-items-center justify-content-between dboard-heading pt-0">
					<h5 className="font-weight-bold bg-white mb-0 d-inline-block" style={{ fontSize: '1rem' }}>Project Status</h5>
					<div>
						<label className='mr-2'>Show with Cost </label>
						<ButtonGroup>
							<Button size="sm"
								color="info"
								outline
								onClick={() => expandToggle(1)}
								active={rSelected === 1}
							>
								Yes
							</Button>
							<Button size="sm"
								color="info"
								outline
								onClick={() => expandToggle(2)}
								active={rSelected === 2}
							>
								No
							</Button>
						</ButtonGroup>
					</div>
				</CardHeader>
				<CardBody className="p-0">
					<table className="table table-bordered table-custom mb-0 status-table">
						<thead>
							<tr>
								<th rowSpan={2} style={{ color: "#3b3e66", textAlign: "center", textWrap: 'nowrap', borderBottomColor: 'rgb(59, 62, 102)' }}>Project Status</th>
								<th colSpan={5} style={{ color: "#3b3e66", textAlign: "center" }} >PPP</th>
								<th rowSpan={2} style={{ color: "#3b3e66", textAlign: "center", borderBottomColor: 'rgb(59, 62, 102)' }} >EPC</th>
								<th rowSpan={2} style={{ color: "#3b3e66", textAlign: "center", borderBottomColor: 'rgb(59, 62, 102)' }} >Total</th>
							</tr>
							<tr>
								<th style={{ color: "#3b3e66", textAlign: "center" }} >BOOT</th>
								<th style={{ color: "#3b3e66", textAlign: "center" }} >BOT Annuity</th>
								<th style={{ color: "#3b3e66", textAlign: "center" }} >BOT Toll</th>
								<th style={{ color: "#3b3e66", textAlign: "center" }} >DBFOT</th>
								<th style={{ color: "#3b3e66", textAlign: "center" }} >HAM</th>
							</tr>
						</thead>
						<tbody>
							{(status_data && status_data.length > 0) && status_data.map((e, index) => (
								<tr key={index}>
								<td width="20%" style={{ color: "#3b3e66", fontSize: '13px', fontWeight: 600, textWrap: 'nowrap', backgroundColor: '#f4f5fd', textTransform: 'uppercase', borderRightColor: 'rgb(59, 62, 102)', borderRightWidth: '2px' }}>{e.project_status}</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 1.1)}>
									<p>{Number(e.boot) > 0 ? e.boot : '-'} </p>{(showPrice && Number(e.boot) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.boot_amt) ?? '-'} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 1.2)}>
									<p>{Number(e.bot_annuity) > 0 ? e.bot_annuity : '-'} </p>{(showPrice && Number(e.bot_annuity) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.bot_annuity_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 1.3)}>
									<p>{Number(e.bot_toll) > 0 ? e.bot_toll : '-'} </p>{(showPrice && Number(e.bot_toll) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.bot_toll_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 1.4)}>
									<p>{Number(e.dbfot) > 0 ? e.dbfot : '-'} </p>{(showPrice && Number(e.dbfot) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.dbfot_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 1.5)}>
									<p>{Number(e.ham) > 0 ? e.ham : '-'} </p>{(showPrice && Number(e.ham) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.ham_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 2)}>
									<p>{Number(e.epc) > 0 ? e.epc : '-'} </p>{(showPrice && Number(e.epc) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.epc_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
								<td width="11%" style={{ textAlign: 'center' }} onClick={() => gotTOProject(e, 'total')}>
									<p>{Number(e.total_project) > 0 ? e.total_project : '-'} </p>{(showPrice && Number(e.total_project) > 0) ? <span><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {Intl.NumberFormat('en-IN', { currency: 'INR' }).format(e.total_project_amt) ?? 0} <small>Crs</small></span> : ''}
								</td>
							</tr>
							))}
						</tbody>
					</table>

				</CardBody>
			</Card>
		</>
	);
}

function ProjectStatusPieCharts(props) {
	const { status_graph } = props;

	let status_graph_category = _.get(status_graph, 'categories');
	let status_graph_color = _.get(status_graph, 'color');
	let status_graph_data = _.get(status_graph, 'result');

	console.log(status_graph_data);
	

	// Create a ref for the chart container
	const container = useRef(null);

	useEffect(() => {
		const chart = Highcharts.chart(container.current, {
			credits: {
				enabled: false
			},
			colors: status_graph_color,
			chart: {
			type: 'bar'
			},
			title: {
				text: 'Project Status (Project Mode Wise)',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			tooltip: {
				outside: true
			},
			xAxis: {
				tickInterval: 1,
				labels: {
					align: 'right',
					useHTML: true,
					allowOverlap: true,
					step: 1,
					y: 3,
					style: {
						fontSize: '13px'
					}
				},
				lineWidth: 0,
				gridLineWidth: 0,
				categories: status_graph_category ? JSON.parse(status_graph_category) : [],
			},
			yAxis: {
				min: 0,
				reversedStacks: false,
				endOnTick: true,
				showLastLabel: true,
				title: {
					text: 'No of Projects',
					align: 'middle'
				},
				labels: {
					overflow: 'justify'
				}
			},
			plotOptions: {
				series: {
					stacking: 'normal',
					dataLabels: {
							enabled: true
					}
			}
			},
			series: status_graph_data ? JSON.parse(status_graph_data) : [],
		});

		// Cleanup function to destroy the chart when component unmounts
		return () => {
			if (chart) {
				chart.destroy();
			}
		};
	}, [status_graph]); // Dependency array

	return (
		<div ref={container} className='polar-chart' style={{ width: '100%', minHeight: '500px' }}></div>
	);
}

function RoadDashboardExpandedCard(props) {

	const { data, road_loading, isExapandedCard } = props;

	//const road = _.has(data, 'road') ? data.road : {};
	const road = data;

	const mode = _.size(road.mode) > 0 ? road.mode : {};

	return (
		<Fragment>
			<Row className="dashboard-sm justify-content-center">
				<NoOfProjectsCountAndAmt {...props} />
				{!road_loading ? (
					<>
						<Col md={6} className="">
							<Card className="card-list-box">
								<CardBody className="p-1">
									<RegionWiseProjectModeChartContainer {...road} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-3">
							<Card className="card-list-box">
								<CardBody className="p-0">
									<ModeOfPieCharts  {...road} isExapandedCard={isExapandedCard} mode={mode} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-3">
							<Card className="card-list-box">
								<CardBody className="p-0">
									<ModeOfPPPPieCharts  {...road} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-3">
							<Card className="card-list-box">
								<CardBody className="p-0">
									<ProjectStatusChartContainer {...road} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-3">
						<Card className="card-list-box">
								<CardBody className="p-0">
									<ProjectStatusPieCharts {...road} />
								</CardBody>
							</Card>
						</Col>
						<Col md={12} className="mt-3">
							<ModeandStatusCountAndAmt {...road} />
						</Col>
						<Col md={6} className="mt-3">
							<Card className="card-list-box">
								<CardBody className="p-0">
									<ProjectGrantAmtBasisAwardCategoryChartContainer  {...road} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-3">
							<Card className="card-list-box">
								<CardBody className="p-0">
									<RoadPhaseDevelopeChartContainer {...road} />
								</CardBody>
							</Card>
						</Col>
					</>
				) : null}
			</Row>
		</Fragment>
	);
}

function RoadDashboardCardMinimize(props) {

	const { data, road_loading } = props;

	//const mode = _.has(data, 'road.mode') ? data.road.mode : {};
	const mode = _.has(data, 'mode') ? data.mode : {};

	let modeDataAvailable = _.size(mode) > 0 ? true : false;


	return (
		<Row>
			<Col md={road_loading ? 12 : 8} className="pr-2 left-side-card-container">
				<NoOfProjectsCountAndAmt {...props} />
			</Col>
			{!road_loading ? (

				<Col md={4} className="">
					<Card className="card-list-box road-pie-chart-card-container">
						<CardBody className="p-1">
							<ModeOfPieCharts mode={mode} {...props} />
						</CardBody>
					</Card>
				</Col>
			) : null}
		</Row>
	);
}

function CardsDetails(props) {

	const { total, amt, title, faicon, isProgress, progressClassName, percent, barSpace, isLoading, children, onClick, progressColor } = props;
	let barChartSpace = !_.isNil(barSpace) ? barSpace : true;
	return (
		<Card className={cx("card-list-box btn px-3 dboard-status-card-box", children ? "py-3" : "py-4")}>
			<div className="d-flex align-items-center" >
				{faicon ?
					(
						<div className="pr-3 status-left-content">
							{isLoading ? <Skeleton width="50px" height="50px" /> : <FontAwesomeIcon icon={faicon} className="text-primary" size="3x" />}
						</div>
					)
					: null}
				<div className="flex-grow-1 pl-2 status-right-content" onClick={() => onClick}>
					<h5 className="border-bottom pb-3 mb-3 font-weight-bold text-left" style={{ fontSize: '0.85rem' }}> {isLoading ? <Skeleton /> : title}</h5>
					{children ? children : (
						<Fragment>
							<div className="d-flex align-items-center pb-4 justify-content-between">
								<div>
									{isLoading ? <Skeleton width="150px" /> : (
										<div className="cost-amt-fsize" >
											<CountUp
												start={0}
												end={total || 0}
												duration={3}
												deplay={2}
												separator=""
												decimals={0}
											/>
										</div>
									)
									}
								</div>
								<div className="cost-amt-fsize text-second">
									{isLoading ? <Skeleton width="150px" /> : <><FontAwesomeIcon icon={['fas', 'rupee-sign']} size="sm" />&nbsp;{amt}<small className="cost-cr-fsize">Crs</small> </>}
								</div>
							</div>
						</Fragment>
					)}

					{isProgress ? (
						<div>
							{isLoading ? <Skeleton width="100%" /> : (
								<>
									{/* <Progress className={cx("progress-animated-alt box-shadow-none", progressClassName ? progressClassName : '', { 'prelative': percent < PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE ? true : false })} value={percent}><span className="center">{percent}%</span></Progress> */}
									<div className={cx("progress progress-animated-alt box-shadow-none", { 'prelative': percent < PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE ? true : false })}>
										<div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: progressColor ? progressColor : "" }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"> <span className="center">{percent}%</span></div>
									</div>
								</>
							)}
						</div>
					) : barChartSpace ? (
						<div style={{ height: '1rem' }}>
							&nbsp;
						</div>
					) : null}
				</div>
			</div>

		</Card>
	);
}

function NoOfProjectsCountAndAmt(props) {
	const history = useHistory()
	const { data, road_loading, isExapandedCard } = props;

	//const road = _.has(data, 'road') ? data.road : {};
	const road = data;
	const under_bidding = _.size(road.status) > 0 ? _.find(road.status, ['key', 4]) : {};
	const under_implementation = _.size(road.status) > 0 ? _.find(road.status, ['key', 1]) : {};
	const shelved = _.size(road.status) > 0 ? _.find(road.status, ['key', 3]) : {};
	const completed = _.size(road.status) > 0 ? _.find(road.status, ['key', 2]) : {};
	const provisionally_completed = _.size(road.status) > 0 ? _.find(road.status, ['key', 6]) : {};
	const force_closed = _.size(road.status) > 0 ? _.find(road.status, ['key', 7]) : {};
	const terminated = _.size(road.status) > 0 ? _.find(road.status, ['key', 8]) : {};
	const eoi_stage = _.size(road.status) > 0 ? _.find(road.status, ['key', 5]) : {};

	const mode = _.size(road.mode) > 0 ? road.mode : {};
	const ppp_mode = _.size(road.ppp_mode) > 0 ? road.ppp_mode : {};

	const gotTOProject = (e) => {

		if (e?.total > 0 || e?.m_mode_id) {
			history.push('/rdprojects', e)
		}

		if (e === true) {
			history.push('/rdprojects', { DTotal: true })
		}
	}

	let data_load = []
	for (let i = 1; i < 10; i++) {
		data_load.push({
			name: i
		})
	}


	return (
		<>
			<Col md="3" className={cx("pb-3 ")} >
				<div onClick={() => gotTOProject(Number(road.total_projects) > 0 ? true : false)}>
					<CardsDetails title="Projects" faicon={['fas', 'road']} total={road.total_projects || 0} amt={road.total_projects_cost_format} isLoading={road_loading} />
				</div>

			</Col>

			{isExapandedCard ? (
				<Fragment>
					{road.status?.length > 0 ?

						<>
							{road.status?.map((e, i) =>

								<Col key={i} md={3} className={cx("pb-3 ")} >
									<div onClick={() => gotTOProject(e)}>
										<CardsDetails key={i} title={e?.name} faicon={[e.fas_icon, e.fas_svg]} total={e.total || 0} amt={e.cost_format} progressClassName="underbidding-progress" progressColor={e?.color} isProgress={true} percent={e.percent} isLoading={road_loading} />
									</div>
								</Col>
							)} </> :
						<>
							{data_load.map((e, i) =>
								<Col key={i} md={3} className={cx("pb-3 ")} >
									<div onClick={() => gotTOProject(e)}>
										<CardsDetails title={e?.name} faicon={[e.fas_icon, e.fas_svg]} total={e.total || 0} amt={e.cost_format} progressClassName="underbidding-progress" progressColor='red' isProgress={true} percent={e.percent} isLoading={road_loading} />
									</div>
								</Col>)}
						</>}
					{/* <Col md={4} className={cx("pb-3 pl-2")} >
                    <div onClick={()=>gotTOProject(eoi_stage)}>
                        <CardsDetails  title="EOI Stage" faicon={['fas', 'cogs']} total={eoi_stage.total || 0} amt={eoi_stage.cost_format} progressClassName="under-implement-progress" isProgress={true} percent={eoi_stage.percent} isLoading={road_loading} />
                    </div>
                    </Col>
                    <Col md={4} className={cx("pb-3 pr-2")} >
                    <div onClick={()=>gotTOProject(under_implementation)}>
                        <CardsDetails  title="Under Implementation" faicon={['fas', 'cubes']} total={under_implementation.total || 0} amt={under_implementation.cost_format} progressClassName="under-implement-progress" isProgress={true} percent={under_implementation.percent} isLoading={road_loading} />
                        </div>
                    </Col>
                    <Col md={4} className={cx('px-2 pb-3')} >
                    <div onClick={()=>gotTOProject(shelved)}>
                        <CardsDetails  title="Shelved" faicon={['fas', 'thumbs-down']} total={shelved.total || 0} amt={shelved.cost_format} progressClassName="shelved-progress" isProgress={true} percent={shelved.percent} isLoading={road_loading} />
                        </div>
                    </Col>
                    <Col md={4} className={cx('pl-2 pb-3')} >
                    <div onClick={()=>gotTOProject(completed)}>
                        <CardsDetails  title="Completed" faicon={['fas', 'thumbs-up']} total={completed.total || 0} amt={completed.cost_format} progressClassName="completed-progress" isProgress={true} percent={completed.percent} isLoading={road_loading} />
                        </div>
                    </Col>

                    <Col md={4} className="pr-2 pb-3"  >
                    <div onClick={()=>gotTOProject(provisionally_completed)}>
                        <CardsDetails  title="Provisionally Completed" faicon={['fas', 'thumbs-up']} total={provisionally_completed.total || 0} amt={provisionally_completed.cost_format} progressClassName="provisionally-completed-progress" isProgress={true} percent={provisionally_completed.percent} isLoading={road_loading} />
                        </div>
                    </Col>
                    <Col md={4} className="px-2 pb-3"  >
                    <div onClick={()=>gotTOProject(force_closed)}>
                        <CardsDetails  title="Foreclosed" faicon={['fas', 'thumbs-down']} total={force_closed.total || 0} amt={force_closed.cost_format} progressClassName="force-closed-progress" isProgress={true} percent={force_closed.percent} isLoading={road_loading} />
                        </div>
                    </Col>
                    <Col md={4} className="pl-2 pb-3">
                    <div onClick={()=>gotTOProject(terminated)}>
                        <CardsDetails  title="Terminated" faicon={['fas', 'thumbs-down']} total={terminated.total || 0} amt={terminated.cost_format}progressClassName="terminated-progress" isProgress={true} percent={terminated.percent} isLoading={road_loading} />
                        </div>
                    </Col> */}
					<div className='clearfix'></div>
					<Col md={6} className={cx('')}>
						<CardsDetails title="Mode of Projects" faicon={['fas', 'cogs']} barSpace={false} isLoading={road_loading}>
							<div className="row d-flex align-items-center justify-content-between">
								<div className='col-md-6' onClick={() => gotTOProject(mode.mode_ppp)}>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<div className='p-2'>
												<span className="text-black-50" style={{ fontSize: '0.93rem' }}>PPP</span>
												<div className="cost-amt-fsize">{_.has(mode, 'mode_ppp') ? mode.mode_ppp.total_project : 0}</div>
												<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(mode, 'mode_ppp') ? mode.mode_ppp.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
											</div>
										</Fragment>
									)}

								</div>
								<div className='col-md-6' onClick={() => gotTOProject(mode.mode_epc)}>{road_loading ? <Skeleton width="250px" /> : (<Fragment>
									<div className='p-2'>
										<span className="text-black-50" style={{ fontSize: '0.93rem' }}>EPC</span>
										<div className="cost-amt-fsize" >{_.has(mode, 'mode_epc') ? mode.mode_epc.total_project : 0}</div>
										<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(mode, 'mode_epc') ? mode.mode_epc.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
									</div>
								</Fragment>
								)}
								</div>
							</div>
						</CardsDetails>
						<div className='mt-3'></div>
						<CardsDetails title="Mode of PPP" faicon={['fas', 'cogs']} barSpace={false} isLoading={road_loading}>
							<div className="d-flex align-items-center justify-content-between">
								<div className='' onClick={() => gotTOProject(ppp_mode.BOOT)}>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<div className='p-2'>
												<span className="text-black-50" style={{ fontSize: '0.93rem' }}>BOOT</span>
												<div className="cost-amt-fsize">{_.has(ppp_mode, 'BOOT') ? ppp_mode.BOOT.total_project : 0}</div>
												<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(ppp_mode, 'BOOT') ? ppp_mode.BOOT.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
											</div>
										</Fragment>
									)}
								</div>
								<div className='' onClick={() => gotTOProject(ppp_mode.BOT_Annuity)}>{road_loading ? <Skeleton width="250px" /> : (<Fragment>
									<div className='p-2'>
										<span className="text-black-50" style={{ fontSize: '0.93rem' }}>BOT Annuity</span>
										<div className="cost-amt-fsize" >{_.has(ppp_mode, 'BOT_Annuity') ? ppp_mode.BOT_Annuity.total_project : 0}</div>
										<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(ppp_mode, 'BOT_Annuity') ? ppp_mode.BOT_Annuity.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
									</div>
								</Fragment>
								)}
								</div>
								<div className='' onClick={() => gotTOProject(ppp_mode.BOT_Toll)}>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<div className='p-2'>
												<span className="text-black-50" style={{ fontSize: '0.93rem' }}>BOT Toll</span>
												<div className="cost-amt-fsize">{_.has(ppp_mode, 'BOT_Toll') ? ppp_mode.BOT_Toll.total_project : 0}</div>
												<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(ppp_mode, 'BOT_Toll') ? ppp_mode.BOT_Toll.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
											</div>
										</Fragment>
									)}
								</div>
								<div className='' onClick={() => gotTOProject(ppp_mode.DBFOT)}>{road_loading ? <Skeleton width="250px" /> : (<Fragment>
									<div className='p-2'>
										<span className="text-black-50" style={{ fontSize: '0.93rem' }}>DBFOT</span>
										<div className="cost-amt-fsize" >{_.has(ppp_mode, 'DBFOT') ? ppp_mode.DBFOT.total_project : 0}</div>
										<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(ppp_mode, 'DBFOT') ? ppp_mode.DBFOT.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
									</div>
								</Fragment>
								)}
								</div>
								<div className='' onClick={() => gotTOProject(ppp_mode.HAM)}>{road_loading ? <Skeleton width="250px" /> : (<Fragment>
									<div className='p-2'>
										<span className="text-black-50" style={{ fontSize: '0.93rem' }}>HAM</span>
										<div className="cost-amt-fsize" >{_.has(ppp_mode, 'HAM') ? ppp_mode.HAM.total_project : 0}</div>
										<div className="cost-amt-fsize mt-2"> <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> {_.has(ppp_mode, 'HAM') ? ppp_mode.HAM.project_cost_format : 0}<small className="cost-cr-fsize">Crs</small></div>
									</div>
								</Fragment>
								)}
								</div>
							</div>
						</CardsDetails>
					</Col>
				</Fragment>
			) : (
				<Fragment>

					<Col md={4} className="px-2 pb-3">
						<CardsDetails onClick={gotTOProject} title="Under Bidding" faicon={['fas', 'question']} total={under_bidding.total || 0} amt={under_bidding.cost_format} progressClassName="underbidding-progress" isProgress={true} percent={under_bidding.percent} isLoading={road_loading} />
					</Col>
					<Col md={4} className={cx("pb-3", isExapandedCard ? "pl-2" : "px-2")}>
						<CardsDetails onClick={gotTOProject} title="Under Implementation" faicon={['fas', 'cubes']} total={under_implementation.total || 0} amt={under_implementation.cost_format} progressClassName="under-implement-progress" isProgress={true} percent={under_implementation.percent} isLoading={road_loading} />
					</Col>
					<Col md={4} className={cx('pr-2', isExapandedCard ? "pb-3" : "")}>
						<CardsDetails onClick={gotTOProject} title="Shelved" faicon={['fas', 'thumbs-down']} total={shelved.total || 0} amt={shelved.cost_format} progressClassName="shelved-progress" isProgress={true} percent={shelved.percent} isLoading={road_loading} />
					</Col>
					<Col md={4} className={cx('px-2', isExapandedCard ? "pb-3" : "")}>
						<CardsDetails onClick={gotTOProject} title="Completed" faicon={['fas', 'thumbs-up']} total={completed.total || 0} amt={completed.cost_format} progressClassName="completed-progress" isProgress={true} percent={completed.percent} isLoading={road_loading} />
					</Col>
					<Col md={4} className={cx(isExapandedCard ? "pl-2" : "px-2")}>
						<CardsDetails title="Mode of Projects" faicon={['fas', 'cogs']} isLoading={road_loading}>
							<div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: '1.15rem' }}>
								<div>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<span className="text-black-50" style={{ fontSize: '0.93rem' }}>PPP</span>
											<div className="cost-amt-fsize">{_.has(mode, 'mode_ppp') ? mode.mode_ppp.total_project : 0}</div>
										</Fragment>
									)}
								</div>
								{/* <div>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<span className="text-black-50" style={{ fontSize: '0.93rem' }}>HAM</span>
											<div className="cost-amt-fsize">{_.has(mode, 'mode_ham') ? mode.mode_ham.total_project : 0}</div>
										</Fragment>
									)}
								</div> */}
								<div>
									{road_loading ? <Skeleton width="250px" /> : (
										<Fragment>
											<span className="text-black-50" style={{ fontSize: '0.93rem' }}>EPC</span>
											<div className="cost-amt-fsize">{_.has(mode, 'mode_epc') ? mode.mode_epc.total_project : 0}</div>
										</Fragment>
									)}
								</div>
							</div>
						</CardsDetails>
					</Col>

				</Fragment>
			)}
		</>
	);
}

function ModeOfPieCharts(props) {
	const { mode, isExapandedCard } = props;

	const container = useRef(null);

	useEffect(() => {

		let dataLabelsOptions = {
			enabled: true,
			distance: 10,
			format: '{point.name}<br/>{point.percentage:.2f} %',
			useHTML: true,
			style: {
				fontSize: '0.70rem',
				fontWeight: '400',
				color: '#3b3e66'
			}
		};
		if (!isExapandedCard) {

			if ($(window).outerWidth() >= 1200 && $(window).outerWidth() <= 1440) {
				$(container.current).css({ height: '260px', 'min-height': '260px', 'max-height': '260px' });

				dataLabelsOptions = {
					enabled: true,
					distance: 2,
					format: "{point.name}<br/>{point.percentage:.2f}%",
					useHTML: true,
					style: {
						fontSize: '0.65rem',
						fontWeight: '400',
						color: '#3b3e66'
					}
				};

			} else {
				$(container.current).css({ height: ($(".left-side-card-container").outerHeight() - 10) + "px" });
			}

		} else {
			$(container.current).css({ height: "400px" });
		}

		Highcharts.chart(container.current, {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			colors: [
				//'#910000', // PPP
				//'#1aadce', //HAM
				//'#492970' // EPC
				'#f28f43', //PPP
				// '#77a1e5', //HAM
				'#1aadce' //EPC
			],
			title: {
				text: isExapandedCard ? 'Project Mode' : null,
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			subtitle: {
				//text: 'Project Mode'
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: dataLabelsOptions
				}
			},
			series: [{
				name: 'Project Mode',
				colorByPoint: true,
				data: [{
					name: 'PPP',
					y: _.get(mode, 'mode_ppp.total_project') || 0
				},
				// {
				// 	name: 'HAM',
				// 	y: _.get(mode, 'mode_ham.total_project') || 0
				// }, 
				{
					name: 'EPC',
					y: _.get(mode, 'mode_epc.total_project') || 0
				},
					// {
					// 	name: 'Item Rate',
					// 	y: _.get(mode, 'mode_item_rate.total_project') || 0
					// }
				]
			}]
		});

	}, [mode, isExapandedCard]);

	return (
		<div ref={container} style={{ width: '100%', height: '310px' }}></div>
	);
}

function ModeOfPPPPieCharts(props) {
	const { graph_data } = props;

	// Extract data from props
	const status_data = _.get(graph_data, 'ppp_mode.data');
	const status_graph_data = _.get(graph_data, 'ppp_mode.graph');

	// Create a ref for the chart container
	const container = useRef(null);

	useEffect(() => {
		// Create the chart when component mounts or when `status_graph_data` changes
		const chart = Highcharts.chart(container.current, {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			colors: [
				'#0099ff', // Under Implementation
				'#00cc66', // Completed
				'#ff6666', // Shelved
				'#944dff', // Under Bidding
				'#FFFF00', // EOI Stage
				// '#39ff14', // Provisionally Completed
				// '#228C22', // Foreclosed
				// '#FF2800'  // Terminated
			],
			title: {
				text: 'Mode of PPP',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						distance: 10,
						format: '{point.name}<br/>{point.percentage:.2f} %',
						useHTML: true,
						style: {
							fontSize: '0.70rem',
							fontWeight: '400',
							color: '#3b3e66'
						}
					}
				}
			},
			series: [{
				name: 'Project Status',
				colorByPoint: true,
				data: status_graph_data
			}]
		});

		// Cleanup function to destroy the chart when component unmounts
		return () => {
			if (chart) {
				chart.destroy();
			}
		};
	}, [status_graph_data]); // Dependency array

	return (
		<div ref={container} style={{ width: '100%', minHeight: '400px' }}></div>
	);
}

function ProjectStatusChartContainer(props) {
	const { graph_data } = props;

	// Extract data from props
	const status_data = _.get(graph_data, 'status.data');
	const status_graph_data = _.get(graph_data, 'status.graph');

	// Create a ref for the chart container
	const container = useRef(null);

	useEffect(() => {
		// Create the chart when component mounts or when `status_graph_data` changes
		const chart = Highcharts.chart(container.current, {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			colors: [
				'#0099ff', // Under Implementation
				'#00cc66', // Completed
				'#ff6666', // Shelved
				'#944dff', // Under Bidding
				'#FFFF00', // EOI Stage
				'#39ff14', // Provisionally Completed
				'#228C22', // Foreclosed
				'#FF2800'  // Terminated
			],
			title: {
				text: 'Project Status',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						distance: 10,
						format: '{point.name}<br/>{point.percentage:.2f} %',
						useHTML: true,
						style: {
							fontSize: '0.70rem',
							fontWeight: '400',
							color: '#3b3e66'
						}
					}
				}
			},
			series: [{
				name: 'Project Status',
				colorByPoint: true,
				data: status_graph_data
			}]
		});

		// Cleanup function to destroy the chart when component unmounts
		return () => {
			if (chart) {
				chart.destroy();
			}
		};
	}, [status_graph_data]); // Dependency array

	return (
		<div ref={container} style={{ width: '100%', minHeight: '500px' }}></div>
	);
}

function ProjectGrantAmtBasisAwardCategoryChartContainer(props) {
	const { graph_data } = props;

	let grant_amt_data = _.get(graph_data, 'grant_amt.data');
	let grant_amt_graph_data = _.get(graph_data, 'grant_amt.graph');

	const container = useRef(null);

	useEffect(() => {
		Highcharts.chart(container.current, {
			chart: {
				zoomType: 'xy'
			},
			title: {
				text: 'Grant Amount Basis Award',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			credits: {
				enabled: false
			},
			xAxis: [{
				categories: _.has(grant_amt_graph_data, 'category') ? grant_amt_graph_data.category : [],
				crosshair: true
			}],
			yAxis: [{ // Primary yAxis
				labels: {
					format: '{value}',
					style: {
						color: Highcharts.getOptions().colors[0]
					}
				},
				title: {
					text: 'Amount in Crores( Rs )',
					style: {
						color: Highcharts.getOptions().colors[0]
					}
				},
				//max: _.has(grant_amt_graph_data, 'project_amt') ? _.max(grant_amt_graph_data.project_amt) : _.noop(),
			}, { // Secondary yAxis
				title: {
					text: 'No of Projects',
					style: {
						color: Highcharts.getOptions().colors[1]
					}
				},
				labels: {
					//format: '{value}',
					style: {
						color: Highcharts.getOptions().colors[1]
					}
				},
				//max: _.has(grant_amt_graph_data, 'project_count') ? _.max(grant_amt_graph_data.project_count) : _.noop(),
				opposite: true
			}],
			tooltip: {
				shared: true
			},
			series: [{
				name: 'Amount in Crores',
				type: 'column',
				data: _.has(grant_amt_graph_data, 'project_amt') ? grant_amt_graph_data.project_amt : [],
				tooltip: {
					valuePrefix: 'Rs '
				}

			}, {
				name: 'No of Projects',
				type: 'line',
				yAxis: 1,
				data: _.has(grant_amt_graph_data, 'project_count') ? grant_amt_graph_data.project_count : [],
				tooltip: {
					//valueSuffix: ' C'
				}
			}]
		});

	}, [grant_amt_graph_data]);

	return (
		<div ref={container} style={{ width: '100%', minHeight: '400px' }}></div>
	);
}

function RoadPhaseDevelopeChartContainer(props) {

	const { graph_data } = props;

	let phase_data = _.get(graph_data, 'phase.data');
	let phase_graph_data = _.get(graph_data, 'phase.graph');

	const container = useRef(null);

	useEffect(() => {
		Highcharts.chart(container.current, {
			chart: {
				type: 'bar'
			},
			title: {
				text: 'Road Phase Development',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			xAxis: {
				categories: _.has(phase_graph_data, 'category') ? phase_graph_data.category : [],
				title: {
					text: null
				},

			},
			//colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
			yAxis: {
				min: 0,
				title: {
					text: 'No of Projects',
					align: 'middle'
				},
				labels: {
					overflow: 'justify'
				}
			},
			tooltip: {
				valueSuffix: ''
			},
			plotOptions: {
				bar: {
					colorByPoint: true,
					dataLabels: {
						enabled: true
					},
					//colors: _.reverse(['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'])
					colors: _.has(phase_graph_data, 'colors') ? phase_graph_data.colors : []
				}
			},
			credits: {
				enabled: false
			},
			legend: false,
			series: [{
				name: 'No of Projects',
				data: _.has(phase_graph_data, 'data') ? phase_graph_data.data : []
			}]
		});

	}, [phase_graph_data]);

	return (
		<div ref={container} style={{ width: '100%', minHeight: '400px' }}></div>
	);
}

function RegionWiseProjectModeChartContainer(props) {
	const { graph_data } = props;

	let region_mode_data = _.get(graph_data, 'region_mode.data');
	let region_mode_graph_data = _.get(graph_data, 'region_mode.graph');

	const container = useRef(null);

	useEffect(() => {
		Highcharts.chart(container.current, {
			chart: {
				type: 'column'
			},
			credits: {
				enabled: false
			},
			title: {
				text: 'Region Wise Mode of Projects',
				style: {
					fontSize: '0.95rem',
					fontWeight: '600',
					color: '#3b3e66'
				}
			},
			xAxis: {
				categories: _.has(region_mode_graph_data, 'category') ? region_mode_graph_data.category : [],
				crosshair: true,
			},
			yAxis: {
				min: 0,
				title: {
					text: 'No of Projects'
				}
			},
			colors: [
				'#f28f43', //PPP
				'#1aadce', //EPC
				// '#77a1e5', //HAM
				// '#f72e2e', //item rate
			],
			tooltip: {
				headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
				pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
					'<td style="padding:0"><b>{point.y}</b></td></tr>',
				footerFormat: '</table>',
				shared: true,
				useHTML: true
			},
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0
				}
			},
			series: _.has(region_mode_graph_data, 'data') ? region_mode_graph_data.data : []
		});

	}, [region_mode_graph_data]);

	return (
		<div ref={container} style={{ width: '100%', minHeight: '309px' }}></div>
	);
}