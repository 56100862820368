import React, { Component, Fragment, useState, useMemo, useCallback ,useEffect} from 'react';
import Select, { Option } from 'rc-select';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTable, usePagination, useGlobalFilter, useExpanded, } from 'react-table';
import { Button, UncontrolledTooltip, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, CustomInput, Modal, ModalBody, InputGroup, InputGroupAddon, ButtonGroup } from 'reactstrap';
import moment from 'moment';
import { ApiRoad, Request, Server, SweetAlert } from "utils";
import cx from 'classnames';
import { AjaxLoader } from 'components/common';
import Pagination from 'components/Pagination';



const UploadFileCard = ({data_use=[],projectId,addDoc}) => {
  // let data_use = [
  //   {
  //     value:1,
  //     label:'RFP'
  //   },
  //   {
  //     value:2,
  //     label:'RFQ'
  //   },
  //   {
  //     value:3,
  //     label:'Letter Of Award'
  //   },
  //   {
  //     value:4,
  //     label:'Concession Agreement'
  //   },
  //   {
  //     value:5,
  //     label:'Construction Comm'
  //   },
  //   {
  //     value:6,
  //     label:'Actual COmpletion'
  //   },
  //   {
  //     value:7,
  //     label:'Schedule Project Comp'
  //   },
  //   {
  //     value:8,
  //     label:'Anticipate Completion'
  //   },
  //   {
  //     value:9,
  //     label:'Financial closer'
  //   },
  //   {
  //     value:10,
  //     label:'Basis of Award'
  //   },
  //   {
  //     value:1000,
  //     label:'Others'
  //   },
  // ]
  const [selectvalue, setSelectvalue] = useState()
  const [addfile, setAddfile] = useState(false)
  const [files, setFiles] = useState([]);
  const [uploadSource,setUploadSource]=useState([])
  const [error,setError]=useState({})
  const[refresh,setRefresh]=useState(false)
  const [loading,setLoading]=useState(false)
  const [getData,setGetData]=useState([])
  const [postPerPage,setPostPerPage]=useState(5)
  const[currentPage,setCurrentPage]=useState(1)



  const reload=()=>{

    setRefresh(!refresh)
  }

  let data_users = []

  for (let i = 1; i <= 5; i++) {
    data_users.push({
      id: i,
      name: "Screenshot.pdf" + i,
      file_type: "pdf" + i,
      uploaded_by: "Test admin" + i,
      uploaded_on: `${moment(new Date()).format("DD-MMM-YYYY HH:mm:ss a")}`
    })

  }

  const labelvalues = data_use

  const onSortingByChangeList = (e) => {
    setSelectvalue(e)
  }

  const validate=(value,selectvalue)=>{
    var error={}
      if(!value.remarks){
        error.remark="required"
      }
      if(!value.doc_source){
        error.doc_source="required"
      }
      if(!selectvalue){
        error.selectValue="required"
      }
    return error
  }

  const upload_doc=()=>{

    setError(validate(uploadSource[0],selectvalue))
    const fomData= new FormData()
    fomData.append('id_road_project',projectId)
    fomData.append('m_doc_type_id',selectvalue)
    fomData.append('files',...files)
    fomData.append('doc_source',uploadSource[0].doc_source)
    fomData.append('remarks',uploadSource[0].remarks)
    fomData.append('id_road_doc',-1)
    fomData.append('is_deleted',0)
    if(Object.keys(validate(uploadSource[0],selectvalue)).length===0){
      setLoading(true)
      Request.uploadFiles("saveRoadDoc",fomData,2).then((res)=>{
        if(res.error===0){
          SweetAlert._autoCloser('Success', 'data successfully saved', 'success');
          setFiles([])
          setUploadSource([])
          setLoading(false)
          reload()
        }
      }).catch((e)=>{
        SweetAlert._autoCloser('error', e, 'error');
        setLoading(false)
      })

    }
  }



  const sourceUpdate=(value,name,i)=>{
      const list=[...uploadSource]
      list[i][name]=value
      setUploadSource(list)

  }

  const removeFile = (index) => {
    const newFiles = [...files];
    const list=[...uploadSource]
    newFiles.splice(index, 1);
    list.splice(index,1)
    setUploadSource(list)
    setFiles(newFiles);
  };

  const MultipleFileUpload = ({sourceUpdate,setFiles,files}) => {

    const onDrop = useCallback((acceptedFiles) => {
      setUploadSource([])
       if(acceptedFiles[0].size<=labelvalues?.file_size){
          setFiles((prevFiles) => [...acceptedFiles]);
          acceptedFiles.map((e)=>{
            setUploadSource((pres)=>[...pres,{doc_source:'',remarks:'',files:[e]}]
            );
          })
        }else{
          SweetAlert._confirm({title:'Max File Size Should Be '+Math.round(labelvalues?.file_size / 1000000)+' MB' });
        }
    }, []);



    const { getRootProps, getInputProps, isDragActive ,acceptedFiles } = useDropzone({ onDrop , maxFiles:1});

    return (
      <>

        <div {...getRootProps()} className={`dropzone-file ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} multiple={false} />
          <p style={{ margin: "0" }}><i className="fa-solid fa-folder-arrow-up px-2" style={{ fontSize: "16px" }}></i>Drag & Drop some Documents here, or click to select Documents</p>
        </div>

      </>

    );
  };

useEffect(()=>{
  const data={
    id_road_project :projectId
  }
  Request.post("getRoadDoc",data,2).then((res)=>{

  if(res.meta['data']){
    setGetData(res.meta.data)
  }else{
    setGetData([])
  }

  })
},[refresh])

const changeOn = (current, pageSize) => {
  // gotoPage(current - 1)
setCurrentPage(current)
}

const changePage = (PageChange, size) => {
  setPostPerPage(size)
}
useEffect(() => {
  changePage(1, 5)
}, [])


const indexOfLastPost = currentPage * postPerPage;
   const indexOfFirstPost = indexOfLastPost - postPerPage;
   const currentPosts = getData.slice(indexOfFirstPost, indexOfLastPost);
  const deleteProjectData = (obj) => {
    const fomData=new FormData()
    fomData.append('id_road_doc',obj.id_road_doc)
    fomData.append('id_road_project',projectId)
    fomData.append('is_deleted',"1")
    SweetAlert.confirm({
        title: `Delete "${obj.file_name}" Details`,
        text: 'Are you sure want to delete?',
        icon: "warning",
        type: 'warning',
        buttons: ['Cancel', 'Yes, delete it'],
        dangerMode: true,
        closeOnClickOutside: false
    }, (isConfirm) => {
        if (isConfirm) {
          Request.uploadFiles("saveRoadDoc",fomData,2).then((res)=>{
            if(res.error===0){
              reload()
              SweetAlert._autoCloser('success',res.msg,'success')
            }

          })
        }
    });
}

  return (
    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
      <CardBody >
        <div className="text-left mb-2 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f7f7f7' }}>
          <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '1rem' }}>Project Documents</div>
         {
        !addfile? <Button size="sm" color="primary" id="AddEntryTooltip20" className="add-update-btn-page-title my-2" onClick={() => {setAddfile(true);addDoc()}}>
        <span className="btn-wrapper--icon">
          <i className='fa-solid fa-plus'></i> Add Documents
        </span>
        </Button>:  <button className='btn-close text-end' onClick={() =>{ setAddfile(false);addDoc();setFiles([])}}></button>
         }
        </div>

        {addfile === true &&
        <>
        <Label className="file-type">Document Type</Label>
        <Row className="align-items-center justify-content-between mb-2">
          <Col md={Number(selectvalue)===1000?4:3} className="d-flex align-items-center" style={{ marginBottom: "0" }}>
            <Select style={{ width: '100%', fontSize: "15px" }} allowClear dropdownClassName="rc-dropdown-styles" className={cx( "mr-2",{ "is-invalid": error.selectValue })} placeholder="Select"  optionLabelProp="children" value={selectvalue} optionFilterProp="text" onChange={(e) => { onSortingByChangeList(e) }}>
              {
                labelvalues.data.map((e) => (
                  <Option value={e.m_doc_type_id} text={e.doc_name} key={e.m_doc_type_id}>{e.doc_name}</Option>
                ))
              }
            </Select>
              {/* {
                Number(selectvalue)===1000&& <Input  type="text" id="road_project_name" onChange={(e)=>{
                  if(e.length<=10){
                      return e
                  }
                }}  className="rounded-0" bsSize="sm"  />
              } */}
          </Col>
        </Row>
          <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
            <CardBody>

              <>
                < MultipleFileUpload  sourceUpdate={sourceUpdate} uploadSource={uploadSource} setFiles={setFiles} files={files}/>
                <div className='mt-1 upload-content'>
          {files.length > 0 &&
            <>
              <Label>Project Documents:</Label>
              <table className='w-100'>
              <thead>
                          <tr>
                          <th className='fw-bold ' style={{width:'20%'}}>
                            Document Name
                          </th>
                          {/* <th className='fw-bold ' style={{width:'10%'}}>
                            Document Size
                          </th> */}

                          <th className='fw-bold p-1 'style={{width:'30%'}}>
                            Source
                          </th>
                          <th className='fw-bold p-1' style={{width:'30%'}}>
                            Remark
                          </th>
                          </tr>
                        </thead>
                <tbody>
                  {files.map((file, index) => {

                      return(
                        <>
                        <tr>
                        <td  >
                          {file.name} (<small style={{color:'red'}}>{((file.size)/(1024 ** 2)).toFixed(2)} MB </small>)
                        </td>
                        {/* <td ></td> */}
                        <td  className='p-1'>
                           <Input type="textarea" bsSize="sm" className="rounded-0"  invalid={error["doc_source"]?true:false} value={uploadSource[index]?.doc_source||''}  onChange={(e)=>sourceUpdate(e.target.value,"doc_source" ,index)}/>
                           <p className='m-0'>{error["doc_source"]}</p>
                      </td>
                        <td className='p-1'>
                           <Input type="textarea" invalid={error["remark"]?true:false} bsSize="sm"   className="rounded-0"  value={uploadSource[index]?.remarks||''}  onChange={(e)=>sourceUpdate(e.target.value,"remarks",index)}/>
                           <p className='m-0'>{error["remark"]}</p>
                         </td>

                        </tr>


                    {/*
                       <td className='text-center'>
                         <Button outline size="sm" className="btn btn-sm btn-outline-danger btn-sm-icon border-1" onClick={() => removeFile(index)}><i class="fa-solid fa-minus"></i></Button>
                         </td> */}

                      </>
                      )
                  })}

                </tbody>

              </table>
                <div className='d-flex justify-content-end m-2'>
                  <div className='d-flex gap-2'>
                  <Button outline size="sm" className="btn btn-sm btn-outline-danger btn-sm-icon border-1" onClick={() => {setFiles([])}}>Clear</Button>
                  <Button outline size="sm" className="btn btn-sm btn-outline-success btn-sm-icon border-1"  onClick={() => {upload_doc()}}>Save</Button>
                  </div>
                </div>
            </>
          }
        </div>
              </>

            </CardBody>
          </Card>
          </>
        }
      {
        getData.length>0&&

        <div className='mb-2' >
          <div style={{overflowY:'auto'}}>
          <table className="table table-bordered table-hover table-custom" >
            <thead>
              <tr>
                <th style={{ width: "3%" }} className='text-center'>S.No</th>
                <th style={{ width: "45%" }}>Document Name</th>
                <th style={{ width: "10%" }}>Document Type</th>
                <th style={{ width: "10%" }}>uploaded By</th>
                <th style={{ width: "15%" }}>uploaded On</th>
                <th style={{ width: "7%" }} className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((e,i) =>
                <tr>
                  <td className='text-center'>{((currentPage-1)*postPerPage)+i+1}</td>
                  <td>{e.file_name}</td>
                  <td>{e.doc_name}</td>
                  <td>{e.created_by}</td>
                  <td>{e.created_on}</td>
                  <td><div className='d-flex justify-content-center'>
                    <Button outline size="sm"   href={/^(http|https):\/\/[^ "]+$/.test(e.file_path)?e.file_path:atob(e.file_path)}  target='_blank'className="btn-sm-icon btn-outline-slack border-1 mr-2" > <i class="fa-solid fa-eye"></i></Button>
                    <Button outline size="sm" className="btn btn-sm btn-outline-danger btn-sm-icon border-1" onClick={()=>{deleteProjectData(e)}} > <i class="fa-solid fa-trash"></i></Button>
                  </div></td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          {
            getData.length>5&&

          <Pagination
                           selectComponentClass={Select}
                            pageSizeOptions={['5', '10', '30', '50']}
                            showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                            pageSize={postPerPage}
                            current={currentPage}
                            total={getData.length}
                             onChange={changeOn}
                             onShowSizeChange={changePage}
                            style={{ borderRadius: '4px', fontSize: '12px' }}
                            wrapperClassName="my-2 pagination-container"
                        />
                      }
        </div>
          }
        <AjaxLoader isOpen={loading} pageLoader={true} loaderText="Uploading..." altText={'upload excel gif image'} toggle={() => {}}/>
      </CardBody>
    </Card>
  )
}
export default UploadFileCard;