import packageJson from '../../package.json';
export const IS_PRODUCTION_MODE = packageJson.productionmode;
export const PORTAL_VERSION =
packageJson.version + (!packageJson.productionmode ? ' Local' : '');
//export const SERVER_URL =  IS_PRODUCTION_MODE ? packageJson.api_url : 'http://localhost/idip_v2.0.2/api/';
export const SERVER_URL = IS_PRODUCTION_MODE ? packageJson.api_url : 'http://localhost/idip/api/';
//export const PORTAL_VERSION = "V 2.0.0";
//export const SERVER_URL =  IS_PRODUCTION_MODE ? 'http://v2.idip.co.in/api/' : 'http://localhost/idip_v2/api/';
//export const SERVER_URL =  IS_PRODUCTION_MODE ? 'http://d.idip.co.in/api/' : 'http://localhost/idip_v2/api/';

export const SERVER_BASE_URL = SERVER_URL + 'api.php';
export const SERVER_BASE_URL_ROAD = SERVER_URL + 'rdapi.php';
export const SERVER_BASE_URL_WS = SERVER_URL + 'wsapi.php';
export const APP_SESSION_KEY = Symbol.for('_IDIP').toString();
