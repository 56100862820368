import React, { Fragment, useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Col, Button, Alert } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG } from '../../constants';

export default function ChangePassword(props) { 

    const { saveChangePassword, changePwd: {loading, error, msg}, logedInUser, isOpen, closeModalPopup } = props;

    const [visible, setVisible] = useState(false);
    const [state, setState] = useState({id_login_user: logedInUser.id_login_user});
    const [err, setErr] = useState({});

    const dispatch = useDispatch();

    const onDismiss = () => {
        setVisible(false);
        dispatch({type: USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG});//USER_CHANGE_PWD_SAVE_REQUEST_INIT        
    }

    useEffect(() => {        
        if (!_.isEmpty(msg)) {
            setVisible(true);
        } else { 
            setVisible(false);    
        }

    }, [loading]);

    const handleChange = (val, name) => {
        let _err = { ...err };
        if (_.size(_err)) { 
            if (_.has(_err, name) && !_.isEmpty(val)) { 
                _err = _.omit(_err, [name]);
            }
            setErr(_err);
        }

        setState((prevState) => ({...prevState, [name] : val }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        let errObj = {};

        if (!_.has(state, 'old_pwd') || (_.has(state, 'old_pwd') && _.isEmpty(_.trim(state.old_pwd)))) {
            errObj.old_pwd = true;
        }
        if (!_.has(state, 'new_pwd') || (_.has(state, 'new_pwd') && _.isEmpty(_.trim(state.new_pwd)))) {
            errObj.new_pwd = true;
        }
        if (_.has(state, 'old_pwd') && _.trim(state.old_pwd) && _.has(state, 'new_pwd') && _.trim(state.new_pwd) && state.new_pwd == state.old_pwd) { 
            errObj.new_pwd = true;
            errObj.new_old_same = true;
        }
        if (!_.has(state, 'confirm_pwd') || (_.has(state, 'confirm_pwd') && _.isEmpty(_.trim(state.confirm_pwd)))) {
            errObj.confirm_pwd = true;
        }

        if (_.has(state, 'new_pwd') && _.has(state, 'confirm_pwd') && state.new_pwd !== state.confirm_pwd) { 
            errObj.confirm_pwd = true;
            errObj.confirm_mismacth = true;
        }
        
        if (_.size(errObj)) { 
            setErr(errObj);
        } else {
            saveChangePassword(state).then(res => { 
                setState({ id_login_user: logedInUser.id_login_user });
            });    
        }
    }

    const resetBtn = () => {
        onDismiss();
        closeModalPopup();
    }

    let isApplySmDevice = false;
    if (window.outerWidth >= 1200 && window.outerWidth <= 1440) { 
        isApplySmDevice = true;
    } 

    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container" style={{backgroundColor: '#f7f7f7' }}>
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{ fontSize: '0.83rem'}}>Change Password</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-2 page-drawer-body-container">    
                <Form onSubmit={handleSubmit} className="form-customise">
                    
                    <FormGroup>
                        <Label>Current Password</Label>
                        <Input type="password" bsSize="sm" invalid={err.old_pwd} placeholder="Enter Current Password" autoComplete="off" onChange={e=>{ handleChange(e.target.value, 'old_pwd') }} />                                   
                    </FormGroup>
                    <FormGroup>
                        <Label>New Password</Label>
                        <Input type="password" bsSize="sm" invalid={err.new_pwd} placeholder="Enter New Password" autoComplete="off" onChange={e => { handleChange(e.target.value, 'new_pwd') }} />     
                        {_.has(err, 'new_old_same') && err.new_old_same ? (<div className="invalid-feedback">
                            Please do not enter same old password and new password
                        </div>) : null}
                    </FormGroup>
                    <FormGroup>
                        <Label>Confirm Password</Label>
                        <Input type="password" bsSize="sm" invalid={err.confirm_pwd} placeholder="Enter Confirm Password" autoComplete="off" onChange={e=>{ handleChange(e.target.value, 'confirm_pwd') }} />
                        {_.has(err, 'confirm_mismacth') && err.confirm_mismacth ? (<div className="invalid-feedback">
                            Does not match New Password and Confirm Password
                        </div>) : null}
                    </FormGroup>

                    {/***!_.isEmpty(serverErrorMsg) ? (
                        
                    ) : null **/}

                    {msg ? (
                        <FormGroup>
                            <Alert color={error ? "danger" : "success"} isOpen={visible} toggle={onDismiss}>{msg}</Alert>
                        </FormGroup>
                    ) : null}
                    
                    <FormGroup row className="mt-2 text-center mb-0">
                        {loading ? (
                            <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <Fragment>
                                {isApplySmDevice ? <Col>&nbsp;</Col> : null}        
                                <Col> <Button type="reset" color="warning" outline block onClick={resetBtn} className="form-reset-cancel-btn">Cancel</Button> </Col>
                                <Col> <Button type="submit" color="primary" block className="form-submit-btn"> Save </Button> </Col>
                            </Fragment>
                        )}
                    </FormGroup>
                </Form>
            </div>
        </Fragment>
    );
}