import { ApiRoad, Request, Server } from "utils";
import _ from 'lodash';
import { GET_PROJECTS_LIST_REQUEST_INIT, GET_PROJECTS_LIST_REQUEST_DONE, GET_PROJECTS_LIST_REQUEST_FAILURE,
    GET_MY_PROJECTS_LIST_REQUEST_INIT, GET_MY_PROJECTS_LIST_REQUEST_DONE, GET_MY_PROJECTS_LIST_REQUEST_FAILURE,
    GET_MY_PROJECTS_QUERY_REQUEST_INIT, GET_MY_PROJECTS_QUERY_REQUEST_DONE, GET_MY_PROJECTS_QUERY_REQUEST_FAILURE,
     LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT, LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE,
     SAVE_PROJECTS_DATA_REQUEST_INIT, SAVE_PROJECTS_DATA_REQUEST_DONE, SAVE_PROJECTS_DATA_REQUEST_FAILURE, DELETE_PROJECTS_DATA_REQUEST_INIT, DELETE_PROJECTS_DATA_REQUEST_DONE, DELETE_PROJECTS_DATA_REQUEST_FAILURE, TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT, TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE, TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE, GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT, GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE,GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT,GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE,GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE, GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE,
     GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE, DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE } from "../constants";
import moment from "moment";

export const fnFetchProjectsList = (params = {}) => (dispatch) => {

    dispatch({ type: GET_PROJECTS_LIST_REQUEST_INIT }); //: JSON.stringify(params)
    return ApiRoad.post('', params, { params: { 'for': 'getRoadProjectsList' } })
        .then(({ data, ...res }) => {
            dispatch({ type: GET_PROJECTS_LIST_REQUEST_DONE, payload: data });
            return Promise.resolve(data);
        }).catch(err => {
            dispatch({ type: GET_PROJECTS_LIST_REQUEST_FAILURE, payload: err.toString() });
            return Promise.reject(err.toString());
        });
};

export const getMyRoadQry = (params = {}) => (dispatch) => {
    dispatch({ type: GET_MY_PROJECTS_QUERY_REQUEST_INIT }); //: JSON.stringify(params)
    return ApiRoad.post('', params, { params: { 'for': 'getMyRoadQry' } })
        .then(({ data, ...res }) => {
            dispatch({ type: GET_MY_PROJECTS_QUERY_REQUEST_DONE, payload: data.meta.data });
            return Promise.resolve(data);
        }).catch(err => {
            dispatch({ type: GET_MY_PROJECTS_QUERY_REQUEST_FAILURE, payload: err.toString() });
            return Promise.reject(err.toString());
        });
};



export const fnRoadProjectActions = {
    loadAddProjectInitData(data = {}) {
        return (dispatch) => {
            dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('loadAddProjectInitData', data, Server.ROAD_API).then((res) => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    facility(data = {}) {
        return (dispatch) => {
            dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('getFacilities', data, Server.ROAD_API).then((res) => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    facility(data = {}) {
        return (dispatch) => {
            dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('getFacilities', data, Server.ROAD_API).then((res) => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getDocType(data = {}) {
        return (dispatch) => {
            dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('getDocType', data, Server.API).then((res) => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    saveDataOfProjectDetails(data) {
        return (dispatch) => {
            dispatch({type: SAVE_PROJECTS_DATA_REQUEST_INIT});
            return Request.post('saveDataOfProjectDetails', data, Server.ROAD_API).then(res => {
                dispatch({type: SAVE_PROJECTS_DATA_REQUEST_DONE, payload: res});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: SAVE_PROJECTS_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    deleteProjectData(data) {
        return (dispatch) => {
            dispatch({type: DELETE_PROJECTS_DATA_REQUEST_INIT});
            return Request.post('deleteProjectData', data, Server.ROAD_API).then(res => {
                dispatch({type: DELETE_PROJECTS_DATA_REQUEST_DONE, payload: res});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: DELETE_PROJECTS_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getTimeOverRunCalc(data) {
        return (dispatch) => {
            dispatch({type: TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT});
            return Request.post('getTimeOverRunCalc', data, Server.ROAD_API).then(res => {
                dispatch({type: TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE, payload: res.meta.data});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getProjectDetails(id_road_project) {
        return (dispatch) => {
            dispatch({type: GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT});
            return Request.post('getRoadProjectDetailsById', {id_road_project}, Server.ROAD_API).then((res) => {
                dispatch({type: GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getRoadProjectDetailsLog(id_road_project) {
        return (dispatch) => {
            dispatch({type: GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT});
            return Request.post('getRoadProjectDetailsLog', {id_road_project}, Server.ROAD_API).then((res) => {
                dispatch({type: GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getQryPageInitializeData() {
        return (dispatch) => {
            dispatch({ type: GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('getQryPageInitializeData', {}, Server.ROAD_API).then((res) => {
                dispatch({type: GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({type: GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getQrySearchResults(data) {
        return (dispatch) => {
            dispatch({ type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT});
            return Request.post('getQrySearchResults', data, Server.ROAD_API).then((res) => {
                dispatch({type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    downloadExcelFileData(params = {}, filename = '') {
        return (dispatch) => {
            dispatch({ type: DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT });
            return Request.downloadFiles({
                requestFor: 'exportQrySearchResultDataInExcel',
                data: params,
                filename: filename || `road_data_${moment().format('DD_MMM_YYYY')}_${moment.now()}.xls`
            }, Server.ROAD_API).then(res => {
                dispatch({ type: DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE });
            }).catch(err => {
                dispatch({ type: DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE, payload: err.toString() });
            });
        }
    }
};