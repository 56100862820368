import { CMN_SET_PAGE_DRAWER_TOGGLE } from "../constants";


/*****
 * This functions used for overwrite and commonly specific changes
 * Reducers and here params `state` for COMBINED REDUCERS STATE DEFAULT
 * `action` for our customize actions
 * *****/
export const CommonCrossSliceReducers = ( state, action ) => { 
    switch (action.type) {
        case CMN_SET_PAGE_DRAWER_TOGGLE:
            const { prevState, pageDrawerToggle } = action;
            let themOpt = { ...prevState, ThemeOptions: { ...prevState.ThemeOptions, pageDrawerToggle: action.pageDrawerToggle } };
            return Object.assign({}, state, themOpt);
    }
    return state;
}