import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import { Printer, Settings, CloudDrizzle, Search, Grid, Users, LifeBuoy, Coffee, Box, Briefcase, Layers, Bell } from 'react-feather';


  export default class LivePreviewExample extends Component {

    render() {
      return (
        <Fragment>
          <div className="icon-demo-box">
            <Card className="p-2 text-primary">
              <Bell className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-success">
              <Settings className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-warning">
              <Search className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-danger">
              <Grid className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-dark">
              <Users className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-info">
              <LifeBuoy className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-first">
              <CloudDrizzle className="font-size-xxl"/>
            </Card>
            <Card className="p-2 text-second">
              <Coffee className="font-size-xxl"/>
            </Card>
            <Card className="p-2">
              <Box className="font-size-xxl"/>
            </Card>
            <Card className="p-2">
              <Briefcase className="font-size-xxl"/>
            </Card>
            <Card className="p-2">
              <Layers className="font-size-xxl"/>
            </Card>
            <Card className="p-2">
              <Printer className="font-size-xxl"/>
            </Card>
          </div>

        </Fragment>
      );
    }
  }
