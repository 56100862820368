
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cryptoJs from 'crypto-js';
import { APP_SESSION_KEY } from '../constants';
import _ from 'lodash';
import swal from 'sweetalert';
import $ from 'jquery';

export const useWindowSize = function(){
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize(){
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);

  }, []);
  return size;
};

export const usePrevious = function(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export const toEncryption = (data, isObject = false, SALT_KEY = APP_SESSION_KEY) => {
    if(!data)return null;
    if(isObject){
        data = JSON.stringify(data);
    }
    return cryptoJs.AES.encrypt(data, SALT_KEY).toString();
}

export const toDecryption = (encData, isToObject = false, SALT_KEY = APP_SESSION_KEY) => {
    if(!encData)return null;
    const bytes = cryptoJs.AES.decrypt(encData, SALT_KEY);
    const data = bytes.toString( cryptoJs.enc.Utf8 );
    return isToObject ? JSON.parse(data): data;
}

export const SessionData = {
    set(key, data){
      localStorage.setItem( key, data );
    },
    get(key) {
      return localStorage.getItem(key);
    },
    has(key) {
      return _.has(localStorage, key);
    },
  clear(key) {
    if (_.isArray(key)) {
      if (_.size(key)) {
        _.each(key, (storedName, indx) => {
          if (this.has(storedName)) {
            localStorage.removeItem(storedName);
          }
        });
      }
    } else if (!_.isEmpty(key)) {
      if (this.has(key)) {
        localStorage.removeItem(key);
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}

export const LoginUserDetails = () => {
  const _session_usr = SessionData.get(APP_SESSION_KEY);
  let user = _session_usr != null ? toDecryption(_session_usr, true) : null;
  return user;
}

export const getLoginUserID = () => {
  let user_data = LoginUserDetails();
  return (user_data != null && 'id_login_user' in user_data) ? user_data.id_login_user: null;
}

export const getLoginUserTypeId = () => {
  let user_data = LoginUserDetails();
  return (user_data != null && 'id_user_type' in user_data) ? user_data.id_user_type: null;
}

export function getLoginUserToken() {
  let user_data = LoginUserDetails();
  return user_data != null ? user_data.token: null;
}

export const objectToFormData = function(obj, form, namespace) {

    let fd = form || new FormData();
    let formKey;
    for(let property in obj) {
      if(obj.hasOwnProperty(property)) {
        if(namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
           //objectToFormData(obj[property], fd, property);
          fd.append(formKey, objectToFormData(obj[property], fd, property));
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
};



export const SweetAlert = {
  //Just PopUp for Success or Warning or Error Show
    _autoCloser: (title, msg, type) => {
      swal({
          title: title,
          text: msg,
          timer: 3000,
          type: type,
          icon: type,
          buttons: false,
          closeOnClickOutside: false
      });
  },
  _autoMsg: (title, msg, type) => {
      swal({
          title: title,
          text: msg,
          //timer: 2000,
          type: type,
          icon: type,
          showConfirmButton: true
      });
  },

  //Confirm Alert Functions
  _confirm: (props) => {
      let { title, text, type, confirmBtnTxt, confirmCallBackFn, confirmButtonColor } = props;
      confirmButtonColor = _.isNil(confirmButtonColor) ? "#DD6B55" : confirmButtonColor;
      swal({
          title: title,
          text: text,
          type: type,
          showCancelButton: true,
          confirmButtonColor: confirmButtonColor,
          confirmButtonText: confirmBtnTxt,
          closeOnConfirm: false
      }).then(confirmCallBackFn);
  },
  confirm: (props, confirmCallBackFn = null) => {
    confirmCallBackFn = _.isFunction(confirmCallBackFn) ? confirmCallBackFn : (() => { });
    swal({
      ...props
    })
    .then(confirmCallBackFn);
  }
};

//Form Names & Data Convert String into Object Format
$.fn.extractObject = function() {
  var accum = {};

  function add(accum, namev, value) {
      if (namev.length == 1)
          accum[namev[0]] = value;
      else {
          if (accum[namev[0]] == null)
              accum[namev[0]] = {};
          add(accum[namev[0]], namev.slice(1), value);
      }
  };
  this.find('input, textarea, select').each(function() {
      var inputVal = $(this).val();
      if ($(this).is("input:radio")) {
          inputVal = $("input[name='" + $(this).attr('name') + "']:checked").val();
      }
      if ($(this).is("input:checkbox")) {
          if( $(this)[0].hasAttribute('data-single') ) { //Single Or Individual Data Checkbox
              var _checkBoxData = 0;
              $("input[name='" + $(this).attr('name') + "']:checked").each(function(i, ele) {
                  _checkBoxData = $(ele).val();
              });
              inputVal = _checkBoxData;
          } else {
              var _checkBoxData = [];
              $("input[name='" + $(this).attr('name') + "']:checked").each(function(i, ele) {
                  _checkBoxData.push($(ele).val());
              });
              inputVal = _checkBoxData;
          }
          //inputVal = $("input[name='"+$(this).attr('name')+"']:checked").val();
      }
      if ($(this).is("select")) {
          inputVal = $("select[name='" + $(this).attr('name') + "'] option:selected").val();
      }
      /*if($(this)[0].hasAttribute('name')){
          add(accum, $(this).attr('name').split('.'), inputVal);
      }*/
      if ($(this)[0].hasAttribute('name') && $(this).prop("disabled") == false) {
          add(accum, $(this).attr('name').split('.'), inputVal);
      }
  });
  return accum;
};

export function numericInt(e) {
  $(e.target).val(e.target.value.replace(/[^\d].+/, ""));
  if ((e.which < 48 || e.which > 57)) {
    e.preventDefault();
  }
}

export function numericFloat(e) {
  $(e.target).val(e.target.value.replace(/[^0-9\.]/g, ""));
  //if ((e.which != 46 || $(e.target).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
  if ((e.which != 46 || $(e.target).val().indexOf('.') != -1 ) && (e.which < 48 || e.which > 57)) {
    e.preventDefault();
  }
}

export function validUrl(e) {
  let regx = new RegExp('^(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$');
  if (_.isElement(e.target)) {
    if (_.isEmpty(e.target.value)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    if (e.target.value.match(regx)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    $(e.target).addClass('is-invalid');
    return false;
  } else {
    if (_.isEmpty(e)) {
      return true;
    }
    if (e.match(regx)) {
      return true;
    }
    return false;
  }
}
export function validCinNo(e) {
  let regx = new RegExp('^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$');
  if (_.isElement(e.target)) {
    if (_.isEmpty(e.target.value)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    if (e.target.value.match(regx)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    $(e.target).addClass('is-invalid');
    return false;
  } else {
    if (_.isEmpty(e)) {
      return true;
    }
    if (e.match(regx)) {
      return true;
    }
    return false;
  }
}

export const isValidEmail = (mailId) => {
  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  return validEmailRegex.test(mailId);
}
export function validEmail(e){
  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  if (_.isElement(e.target)) {
    if (_.isEmpty(e.target.value)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    if (e.target.value.match(validEmailRegex)) {
      $(e.target).removeClass('is-invalid');
      return true;
    }
    $(e.target).addClass('is-invalid');
    return false;
  } else {
    if (_.isEmpty(e)) {
      return true;
    }
    if (e.match(validEmailRegex)) {
      return true;
    }
    return false;
  }

}
export function floorFigure(figure, decimals = 2) {
  if (_.isNil(decimals)) {
    decimals = 2;
  }
  var d = Math.pow(10, decimals);
  return ((figure * d) / d).toFixed(decimals);
}