import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from 'lodash';

function CardLabelContents(props) {
    const { label, labelTitle, children } = props;
    return (<><small className="font-weight-light" title={label||''}>{label}</small><div className="text-dark">{children}</div></> );
}

export default function ProjectDetailsSkeletonView(props) { 

    const Arr = [1, 2, 3];

    return (
        <Fragment>
            <Card className="card-list-box project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2">
                        <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="60%" height="20px" /> </div>
                    </div>
                    <Row>
                        <Col md={4}>

                            <Row className="project-view-details-card">
                                <Col md={12}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={8}>
                            <Row className="project-view-details-card">
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col md={3}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className="card-box mb-3 rounded-0 border-0 card-list-box">                            
                <CardBody>
                    <div className="text-left mb-2">
                        <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="30%" height="17px" /> </div>
                    </div>
                    <Row>                            
                        <Col md={5}>
                            <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                        </Col>                      
                        <Col md={3}>
                            <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                        </Col>                      
                        <Col md={4}>
                            <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                        </Col>                      
                    </Row>
                </CardBody>
            </Card>
            <Row>
                <Col md={6} className="pr-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">                            
                        <CardBody>
                            <div className="text-left mb-2">
                                <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="45%" height="17px" /> </div>
                            </div>
                            <Row>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'30%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'30%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>

                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="60%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={12} md={12}>
                                    <small className="font-weight-light">&nbsp;</small><div className="text-dark">&nbsp;</div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="pl-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                        <CardBody>
                            <div className="text-left mb-2">
                                <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="45%" height="17px" /> </div>
                            </div>
                            <Row>                        
                                <Col sm={12} md={12}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>

                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>

                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>

                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                                <Col sm={4} md={4}>
                                    <CardLabelContents label={<Skeleton width={'40%'} />}><Skeleton width="75%" /></CardLabelContents>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="pr-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                        <CardBody>
                            <div className="text-left mb-2">
                                <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="45%" height="17px" /> </div>
                            </div>
                            
                                {
                                    _.map(Arr, (names, indx) => (
                                        <Row className="mx-0" key={names+'__'+indx}>
                                            <Col md={1}>
                                                <div className="text-dark"><Skeleton width="75%" /></div>
                                            </Col>
                                            <Col md={11} className={indx !== (_.size(Arr) - 1) ? "border-bottom pb-2 pl-0" : "pl-0"}>
                                            <div className="text-dark"><Skeleton width="75%" /></div>
                                            </Col>
                                        </Row>  
                                    ) ) 
                                }
                            
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="pl-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                        <CardBody>
                            <div className="text-left mb-2">
                                <div className="mb-0 text-black py-1 px-1" style={{ fontSize: '0.88rem' }}> <Skeleton width="45%" height="17px" /> </div>
                            </div>
                            {
                                _.map(Arr, (names, indx) => (
                                    <Row className="mx-0" key={names+'__'+indx}>
                                        <Col md={1}>
                                            <div className="text-dark"><Skeleton width="75%" /></div>
                                        </Col>
                                        <Col md={11} className={indx !== (_.size(Arr) - 1) ? "border-bottom pb-2 pl-0" : "pl-0"}>
                                        <div className="text-dark"><Skeleton width="75%" /></div>
                                        </Col>
                                    </Row>  
                                ) ) 
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}