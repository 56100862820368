import { createStore, combineReducers, applyMiddleware } from 'redux';
//import reducers from '../reducers';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducers from 'reducers';

export default function configureStore() {
  return createStore(
    /**combineReducers({
      ...reducer
    }),***/
    rootReducers,
    applyMiddleware(
      //logger,
      thunk
    )
  );
}