import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

export default class HeaderUserbox extends Component {
  render() {

    const { loginUserData, changePasswordOpenForm, profileOpenForm } = this.props;

    return (
      <Fragment>
        <UncontrolledDropdown className="user-box position-relative ml-2 login-user-name-content">
          <DropdownToggle color="link" className="p-0 text-left d-flex align-items-center dropdown-toggle-btn">
            
            <div className="d-none d-xl-block pl-2 text-right">
              <div className="font-weight-bold ">
                {loginUserData.user_name}
              </div>
              <span className="text-black-50 ">
                {loginUserData.user_type ? loginUserData.user_type : 'Unknown Type'}
              </span>
            </div>
            <div className="d-block p-0 avatar-icon-wrapper ml-2 mr-0">
              <Badge color="success" className="badge-circle p-top-a">Online</Badge>
              <div className="avatar-icon rounded">
                <img src="/assets/images/avatars/male-user.png" alt="..." />
              </div>
            </div>
            <span className="pl-1 pl-xl-3"><FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5"/></span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg overflow-hidden">

              <DropdownItem onClick={e => { profileOpenForm(); } }>Profile</DropdownItem>
              <DropdownItem onClick={e => { changePasswordOpenForm(); } }>Change Password</DropdownItem>
              <DropdownItem onClick={e => { this.props.logoutEvent(e); } }>Logout</DropdownItem>

            {/***<ListGroup flush className="text-left bg-transparent">
              <ListGroupItem className="rounded-top">
                <Nav pills className="nav-neutral-primary flex-column">
                  
                  <NavItem>
                    <NavLink href='#' onClick={e => e.preventDefault()}>
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#' onClick={e => { e.preventDefault(); changePasswordOpenForm(); } }>
                      Change Password
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#' onClick={e => { e.preventDefault(); this.props.logoutEvent(e); } }>
                      Logout
                    </NavLink>
                  </NavItem>                  
                </Nav>
              </ListGroupItem>
              <ListGroupItem className="bg-transparent p-0">
                <div className="grid-menu grid-menu-2col">
                  <div className="py-3">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex align-items-center">
                        <div>
                            <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xxl text-info" />
                        </div>
                        <div className="pl-3 line-height-sm">
                          <b className="font-size-lg">$9,693</b>
                          <span className="text-black-50 d-block">revenue</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem className="rounded-bottom p-3 text-center">
                <Button tag="a" href='#' onClick={e => e.preventDefault()} color="facebook" id="FacebookTooltipHeader" container="body">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                  </span>
                </Button>
                <UncontrolledTooltip target="FacebookTooltipHeader">
                    Facebook
                </UncontrolledTooltip>
                <Button tag="a" href='#' onClick={e => e.preventDefault()} color="dribbble" className="mr-2 ml-2" id="btnDribbbleTooltipHeader" container="body">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'dribbble']} />
                  </span>
                </Button>
                <UncontrolledTooltip target="btnDribbbleTooltipHeader">
                  Dribbble
                </UncontrolledTooltip>
                <Button tag="a" href='#' onClick={e => e.preventDefault()} color="twitter" id="btnTwitterTooltipHeader" container="body">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                </Button>
                <UncontrolledTooltip target="btnTwitterTooltipHeader">
                  Twitter
                </UncontrolledTooltip>
              </ListGroupItem>
            </ListGroup>***/}
            
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  }
}
