import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {UncontrolledCollapse} from 'reactstrap';

import {
    setThemeConfiguratorToggle,

    setHeaderFixed,
    setHeaderShadow,
    setHeaderBgTransparent,

    setSidebarUserbox,
    setSidebarFooter,
    setSidebarFixed,
    setSidebarShadow,
    setSidebarStyle,

    contentBackground,
    setContentBackground,

    setPageTitleStyle,
    setPageTitleBackground,
    setPageTitleShadow,
    setPageTitleBreadcrumb,
    setPageTitleIconBox,
    setPageTitleDescription,

    setFooterFixed,
    setFooterShadow,
    setFooterBgTransparent,

} from '../../reducers/ThemeOptions';


import Switch from 'rc-switch';

class ThemeConfigurator extends Component {

    // Header

    toggleHeaderFixed = () => {
        let {headerFixed, setHeaderFixed} = this.props;
        setHeaderFixed(!headerFixed);
    }
    toggleHeaderTransparentBg = () => {
        let {headerBgTransparent, setHeaderBgTransparent} = this.props;
        setHeaderBgTransparent(!headerBgTransparent);
    }
    toggleHeaderShadow = () => {
        let {headerShadow, setHeaderShadow} = this.props;
        setHeaderShadow(!headerShadow);
    }

    // Sidebar

    toggleSidebarFixed = () => {
        let {sidebarFixed, setSidebarFixed} = this.props;
        setSidebarFixed(!sidebarFixed);
    }
    toggleSidebarShadow = () => {
        let {sidebarShadow, setSidebarShadow} = this.props;
        setSidebarShadow(!sidebarShadow);
    }
    toggleSidebarUserbox = () => {
        let {sidebarUserbox, setSidebarUserbox} = this.props;
        setSidebarUserbox(!sidebarUserbox);
    }
    toggleSidebarFooter = () => {
        let {sidebarFooter, setSidebarFooter} = this.props;
        setSidebarFooter(!sidebarFooter);
    }

    // Footer

    toggleFooterFixed = () => {
        let {footerFixed, setFooterFixed} = this.props;
        setFooterFixed(!footerFixed);
    }
    toggleFooterTransparentBg = () => {
        let {footerBgTransparent, setFooterBgTransparent} = this.props;
        setFooterBgTransparent(!footerBgTransparent);
    }
    toggleFooterShadow = () => {
        let {footerShadow, setFooterShadow} = this.props;
        setFooterShadow(!footerShadow);
    }

    // Page title

    togglePageTitleIconBox = () => {
        let {pageTitleIconBox, setPageTitleIconBox} = this.props;
        setPageTitleIconBox(!pageTitleIconBox);
    }
    togglePageTitleBreadcrumb = () => {
        let {pageTitleBreadcrumb, setPageTitleBreadcrumb} = this.props;
        setPageTitleBreadcrumb(!pageTitleBreadcrumb);
    }
    togglePageTitleShadow = () => {
        let {pageTitleShadow, setPageTitleShadow} = this.props;
        setPageTitleShadow(!pageTitleShadow);
    }
    togglePageTitleDescription = () => {
        let {pageTitleDescription, setPageTitleDescription} = this.props;
        setPageTitleDescription(!pageTitleDescription);
    }

    // Main content

    toggleThemeConfigurator = () => {
        let {themeConfiguratorToggle, setThemeConfiguratorToggle} = this.props;
        setThemeConfiguratorToggle(!themeConfiguratorToggle);
    }

    render() {
        let {
            themeConfiguratorToggle,

            headerFixed,
            headerShadow,
            headerBgTransparent,

            sidebarFixed,
            sidebarShadow,
            sidebarUserbox,
            sidebarFooter,
            sidebarStyle,
            setSidebarStyle,
            setSidebarShadow,

            contentBackground,
            setContentBackground,

            pageTitleStyle,
            pageTitleBackground,
            pageTitleShadow,
            pageTitleBreadcrumb,
            pageTitleIconBox,
            pageTitleDescription,
            setPageTitleStyle,
            setPageTitleBackground,

            footerFixed,
            footerShadow,
            footerBgTransparent

        } = this.props;

        return (
            <Fragment>
            </Fragment>
    );
    }
}

const mapStateToProps = state => ({
    themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle,

    headerFixed: state.ThemeOptions.headerFixed,
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,

    sidebarFixed: state.ThemeOptions.sidebarFixed,
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarUserbox: state.ThemeOptions.sidebarUserbox,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarStyle: state.ThemeOptions.sidebarStyle,

    pageTitleStyle: state.ThemeOptions.pageTitleStyle,
    pageTitleBackground: state.ThemeOptions.pageTitleBackground,
    pageTitleShadow: state.ThemeOptions.pageTitleShadow,
    pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
    pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
    pageTitleDescription: state.ThemeOptions.pageTitleDescription,

    contentBackground: state.ThemeOptions.contentBackground,

    footerFixed: state.ThemeOptions.footerFixed,
    footerShadow: state.ThemeOptions.footerShadow,
    footerBgTransparent: state.ThemeOptions.footerBgTransparent,
});

const mapDispatchToProps = dispatch => ({

    setThemeConfiguratorToggle: enable => dispatch(setThemeConfiguratorToggle(enable)),

    setHeaderFixed: enable => dispatch(setHeaderFixed(enable)),
    setHeaderShadow: enable => dispatch(setHeaderShadow(enable)),
    setHeaderBgTransparent: enable => dispatch(setHeaderBgTransparent(enable)),

    setSidebarShadow: enable => dispatch(setSidebarShadow(enable)),
    setSidebarFixed: enable => dispatch(setSidebarFixed(enable)),
    setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable)),
    setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
    setSidebarStyle: color => dispatch(setSidebarStyle(color)),

    setPageTitleStyle: color => dispatch(setPageTitleStyle(color)),
    setPageTitleBackground: color => dispatch(setPageTitleBackground(color)),
    setPageTitleShadow: enable => dispatch(setPageTitleShadow(enable)),
    setPageTitleBreadcrumb: enable => dispatch(setPageTitleBreadcrumb(enable)),
    setPageTitleIconBox: enable => dispatch(setPageTitleIconBox(enable)),
    setPageTitleDescription: enable => dispatch(setPageTitleDescription(enable)),

    setContentBackground: color => dispatch(setContentBackground(color)),

    setFooterFixed: enable => dispatch(setFooterFixed(enable)),
    setFooterShadow: enable => dispatch(setFooterShadow(enable)),
    setFooterBgTransparent: enable => dispatch(setFooterBgTransparent(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator);
