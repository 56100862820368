import React, { Component, Fragment, useState, useEffect } from 'react'
import { Modal, Row, Col, Form, FormGroup, Input, Button, Nav, NavItem, NavLink, TabContent, TabPane, CustomInput, Alert } from 'reactstrap'
import { withRouter } from 'react-router-dom';
import {  fnLoginAuthActions } from 'actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginForm } from './LoginForm';
import { UserRegisterForm } from './RegisterForm';
import jQuery from 'jquery';
import { isValidEmail } from 'utils';
import { PropagateLoader } from 'react-spinners';


class LoginModals extends Component {

    constructor(props) { 
        super(props);
        this.state = {
            activeTab: props.activeTab || 1,
            loginData: {},
            isLoginFormSubmit: false,
            loginFormErr: {},
            alertVisible: false,
            forgetPwdAlert: false,
            trialAccResAlert: false,
            selfRegResAlert: false
        };
    }

    static getDerivedStateFromProps(props, state){        
        if(props.activeTab != state.activeTab){            
            return {
                activeTab: props.activeTab
            };
        }
        return null;
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return {
            is_login_req: (prevProps.loading == true && this.props.loading == false && this.props.error == true),
            error: this.props.error,
            errMsg: this.props.errMsg,
            is_get_forget_pwd_res: (prevProps.forgetPwd.loading == true && this.props.forgetPwd.loading == false),
            is_trial_acc_req_res: (prevProps.trialAcc.loading == true && this.props.trialAcc.loading == false),
            is_self_reg_req_res: (prevProps.selfReg.loading == true && this.props.selfReg.loading == false),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log('componentDidUpdate', 'prevProps', prevProps, 'prevState', prevState, 'snapshot', snapshot);
        if (snapshot.is_login_req) {
            this.setState({ alertVisible: true });
        }
        if (snapshot.is_get_forget_pwd_res) { 
            this.setState({ forgetPwdAlert: true });
        }
        if (snapshot.is_trial_acc_req_res) { 
            this.setState({ trialAccResAlert: true });
        }
        if (snapshot.is_self_reg_req_res) { 
            this.setState({ selfRegResAlert: true });
        }
    }

    componentDidMount() {
        this.props.getIpAdminDetailsForSelfReg();
    }

    toggleTab = (activeTabNo) => {
        this.props.setActiveTabFn(activeTabNo);
        //this.setState({ activeTab: activeTabNo });
    }

    toggleAlert = () => {
        this.setState({alertVisible: !this.state.alertVisible});
    }
    
    handleChange = (e) => {
        e.preventDefault();
        let key = e.target.name;
        let val = e.target.value;
        this.setState((prevState, curProp) => {

            let _formErr = {};
            if (_.size(prevState.loginFormErr)) {
                _formErr = { ...prevState.loginFormErr };
                if (_.has(_formErr, key) && _.get(_formErr, key) == true && !_.isEmpty(val)) {
                    _formErr = _.omit(_formErr, key);
                }
            }

            return {
                loginData: {
                    ...this.state.loginData,
                    [key]: val
                },
                loginFormErr: _formErr
            }
        });
    }

    loginSubmit = (e) => {
        e.preventDefault();
        const { user_login, user_pwd } = this.state.loginData;      
        let data = {};
        if (_.isNil(user_login) || _.isNil(user_pwd)) {
            data = {
                user_login: jQuery('input[type="email"]', e.target).val(),
                user_pwd: jQuery('input[type="password"]', e.target).val()
            };    
        } else {
            data = {
                user_login,
                user_pwd
            };
        }
        let err = {};
        if (_.isEmpty(user_login) || _.isEmpty(_.trim(user_login)) || _.isNil(user_login)) {
            err.user_login = true;
        } else if(!_.isEmpty(user_login) && !isValidEmail(user_login) ) { 
            err.user_login = true;
        }
        if (_.isEmpty(user_pwd) || _.isNil(user_pwd)) { 
            err.user_pwd = true;
        }

        //this.setState({ isLoginFormSubmit: true });
        if (_.size(err)) { 
            this.setState({loginFormErr: err})
        } else {
            this.props.loginAuthentication( data );    
        }        
    }

    forgotPassword = (e) => {
        e.preventDefault();
    }

    closeModal = (e) => {
        e.preventDefault();
        this.setState({ activeTab: 1, loginData: {}, isLoginFormSubmit: false });
        this.props.toggle(!this.props.isOpen);
    } 

    createAccSubmit = (data) => {
        this.props.requestTrialAccCreate(data);
    }

    submitForgetPassword = (data) => {
        this.props.forgetPassword(data);
    }
    forgetPwdToggleAlert = () => { 
        this.setState({forgetPwdAlert: !this.state.forgetPwdAlert});
    }
    
    toggleTrialAccResAlert = () => { 
        this.setState({trialAccResAlert: !this.state.trialAccResAlert});
    }
    toggleSelfRegAlert = () => { 
        this.setState({selfRegResAlert: !this.state.selfRegResAlert});
    }

    render() { 
        
        const { selfRegAdminDetails: { data, loading: adminDetatailLoading } } = this.props;

        return (
            <Fragment>                
                <Modal centered size="xl" isOpen={this.props.isOpen} zIndex={1300} toggle={this.props.toggle} backdrop='static' contentClassName="border-0 bg-transparent login-register-popup-modal">
                    <Row className="no-gutters">
                        <Col lg="5" md={5} sm={6} className="order-2 order-lg-1 d-flex">
                            <div className="bg-white rounded-left h-100">
                                <div className={cx('text-center', 'h-100', {'p-0': this.state.activeTab === 1})}>
                                    <Row className="h-100">
                                        <Col lg="12" className="d-none d-lg-flex align-items-center">
                                            {(this.state.activeTab === 1) ?
                                                (/** <img alt="..." className="w-100 mx-auto d-block img-fluid" src={svgImage9} /> **/
                                                <img alt="..." className="rounded-left w-100 h-100 mx-auto d-block img-fluid" src="/assets/images/login/login_bg.png" />
                                                ) :
                                                (<div className="hero-wrapper w-100 bg-composed-wrapper bg-plum-plate h-100 rounded-left left-side-content">
                                                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                                                        <div className="bg-composed-wrapper--image" style={{ backgroundImage: 'url(' + "/assets/images/login/login_bg.png" + ')' }} />
                                                        <div className="bg-composed-wrapper--bg bg-premium-dark opacity-5" />
                                                        <div className="bg-composed-wrapper--content p-5">
                                                            <div className="text-white mt-3">
                                                                <h1 className="display-4 my-3 font-weight-bold">
                                                                    {this.state.activeTab === 3 ? "Reset your account login password" :
                                                                       this.state.activeTab === 4  ? "Why you need to self register?" : "Why should you need a trial account?"}                                                                    
                                                                </h1>
                                                                {/***<p className="font-size-md mb-0 text-white-50">
                                                                    A free hour, when our power of choice is untrammelled and when nothing prevents.
                                                                </p>**/}
                                                                <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                                                            </div>
                                                        </div>                                                    
                                                    </div>
                                                </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg="7" md={7} sm={6}  className="order-1 order-lg-2 d-flex align-items-center">                            
                            <div className={cx({"bg-new-theme-color": this.props.theme == 1, "bg-white": this.props.theme == 0}, "hero-wrapper bg-composed-wrapper h-100 rounded-right w-100")}>
                                <div className="flex-grow-1 w-100 d-flex">
                                    <div className="bg-composed-wrapper--content py-4 px-5">

                                        <Col className="mx-auto px-xl-0">
                                            <div className="py-2">
                                                <Nav className="nav-line">
                                                    <NavItem className="m-0">
                                                        <NavLink className={cx({ active: this.state.activeTab === 1 })} onClick={() => this.toggleTab(1)} >
                                                            <span>Sign in</span>
                                                            <div className="divider"/>
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* {!adminDetatailLoading && (!_.isNil(data) && !data.is_valid_period) ? (
                                                        <NavItem className="m-0">
                                                            <NavLink className={cx({ active: this.state.activeTab === 2 })} onClick={() => this.toggleTab(2)} >
                                                                <span>Request Trial</span>
                                                                <div className="divider"/>
                                                            </NavLink>
                                                        </NavItem>
                                                    ) : null}                                                     */}
                                                    <NavItem className="m-0">
                                                        <NavLink className={cx({ active: this.state.activeTab === 3 })} onClick={() => this.toggleTab(3)} >
                                                            <span>Forget Password</span>
                                                            <div className="divider"/>
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* {(!adminDetatailLoading && (!_.isNil(data) && data.is_valid_period)) ? (
                                                        <NavItem className="m-0">
                                                            <NavLink className={cx({ active: this.state.activeTab === 4 })} onClick={() => this.toggleTab(4)} >
                                                                <span>Self Registration</span>
                                                                <div className="divider"/>
                                                            </NavLink>
                                                        </NavItem>
                                                    ) : null} */}
                                                </Nav>
                                            </div>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId={1}>
                                                    <LoginForm {...this.props} loginFormData={this.state.loginData} isLoginSubmit={this.state.isLoginFormSubmit} closeModal={this.closeModal} loginSubmit={this.loginSubmit} forgotPassword={this.forgotPassword} toggleTab={this.toggleTab} handleChange={this.handleChange} {...this.state} onDismiss={this.toggleAlert}/>
                                                </TabPane>
                                                {/* {!adminDetatailLoading && (!_.isNil(data) && !data.is_valid_period) ? (
                                                    <TabPane tabId={2}>
                                                        <UserRegisterForm {...this.props} {...this.state} toggleTrialAccResAlert={this.toggleTrialAccResAlert} closeModal={this.closeModal} createAccSubmit={this.createAccSubmit}/>
                                                    </TabPane>
                                                ) : null}                                                 */}
                                                <TabPane tabId={3}>
                                                    <UserForgetPasswordForm {...this.props} {...this.state} forgetPwdToggleAlert={this.forgetPwdToggleAlert} closeModal={this.closeModal} submitForgetPassword={this.submitForgetPassword}/>
                                                </TabPane>
                                                 {/* {!adminDetatailLoading && (!_.isNil(data) && data.is_valid_period) ? (
                                                    <TabPane tabId={4}>
                                                        <SelfRegistrationForm {...this.props} {...this.state} closeModal={this.closeModal} toggleSelfRegAlert={this.toggleSelfRegAlert} />
                                                    </TabPane>
                                                 ) : null} */}
                                            </TabContent>
                                        </Col>
                                        {adminDetatailLoading ? null : (!_.isNil(data) && !_.isEmpty(data.ip_address)) ? <p className="text-center ip-address-subtitle"><small>Your Ip Address : </small>{data.ip_address}</p>: null }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //...state,
        isLoggedIn: !_.isNil(state.Authentication.isLoggedIn) ? state.Authentication.isLoggedIn : false,
        error: state.Authentication.error,
        loading: state.Authentication.loading,
        errMsg: state.Authentication.msg,
        forgetPwd: state.Authentication.forgetPwd || {},
        trialAcc: state.Authentication.trialAcc || {},
        selfReg: state.Authentication.selfReg || {},
        selfRegAdminDetails: state.Authentication.selfRegAdminDetails || {}
    };
};

const mapDispatchToProps = (dispatch) => { 
    return {
        //loginAuthentication: (data) => dispatch( fnLoginAuthentication( data ) ) 
        loginAuthentication: (data) => dispatch(fnLoginAuthActions.login(data)),
        forgetPassword: (data) => dispatch( fnLoginAuthActions.forgetPassword(data) ),
        requestTrialAccCreate: (data) => dispatch(fnLoginAuthActions.requestTrialAccCreate(data)),
        selfRegisterToCreateAcc: (data) => dispatch(fnLoginAuthActions.selfRegisterToCreateAcc(data)),
        getIpAdminDetailsForSelfReg: () => dispatch(fnLoginAuthActions.getIpAdminDetailsForSelfReg())
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginModals));

function SelfRegistrationForm(props) {
    const {closeModal, selfReg, selfRegisterToCreateAcc, activeTab, selfRegAdminDetails: { data, loading: adminDetatailLoading }, selfRegResAlert, toggleSelfRegAlert} = props;
    
    const [resMsg, setResMsg] = useState({ msg: '', type: 'danger'});
    const [err, setErr] = useState({});
    const [state, setState] = useState({id_login_user_admin: data.id_login_user_admin});

    useEffect(() => {
        
        setResMsg({ msg: '', type: 'danger' });
        setErr({});
        setState({ id_login_user_admin: data.id_login_user_admin });
        
        return () => { 
            
        }
    }, [activeTab]);


    const _handleChange = (val, name) => {
        let _err = { ...err };
        if (_.size(_err)) {
            if (_.has(_err, name) && !_.isEmpty(val)) { 
                _err = _.omit(_err, [name]);
            }
            setErr(_err);
        }

        if(name === 'pwd') {
            if (_.isEmpty(_.trim(val)) || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(val)) {
                setResMsg({ msg: 'Password must include at least one uppercase letter and one number', type: 'danger' });
            } else {
                setResMsg({msg: null, type: 'danger'});
                if (!_.isNil(val) && !_.isNil(state.confirm_pwd) && !_.isEqual(state.confirm_pwd, val)) { 
                    setResMsg({ msg: 'Password & Confirm password does not match', type: 'danger' });                     
                } else {
                    setResMsg({msg: null, type: 'danger'});
                }
            }
        }
        if(name === 'confirm_pwd') {
            if (!_.isNil(val) && !_.isNil(state.pwd) && !_.isEqual(val, state.pwd)) { 
                setResMsg({ msg: 'Password & Confirm password does not match', type: 'danger' });                     
            } else {
                setResMsg({msg: null, type: 'danger'});
            }
        }

        setState((prevState) => ({...prevState, [name]: val}));
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        let _errObj = {};
        if (_.isNil(state.email_id) || (_.has(state, 'email_id') && _.isEmpty(_.trim(state.email_id)))) {
            _errObj.email_id = true;
        } else if (!isValidEmail(state.email_id)) {
            _errObj.email_id = true;
            setResMsg({ msg: 'Enter valid email id', type: 'danger' });
            
        }
        if (_.isNil(state.name) || (_.has(state, 'name') && _.isEmpty(_.trim(state.name)))) {
            _errObj.name = true;
        }
        if (_.isNil(state.pwd) || (_.has(state, 'pwd') && _.isEmpty(_.trim(state.pwd)))) {
            _errObj.pwd = true;
        }
        if (_.isNil(state.confirm_pwd) || (_.has(state, 'confirm_pwd') && _.isEmpty(_.trim(state.confirm_pwd)))) {
            _errObj.confirm_pwd = true;
        } else if (!_.isNil(state.confirm_pwd) && !_.isNil(state.pwd) && !_.isEqual(state.confirm_pwd, state.pwd)) { 
            _errObj.confirm_pwd = true;
            setResMsg({ msg: 'Password & Confirm password does not match', type: 'danger' });                     
        }

        if (_.size(_errObj)) {
            setErr(_errObj);
        } else {
            selfRegisterToCreateAcc(state).then(res => {
                setState({id_login_user_admin: data.id_login_user_admin});
                _.delay(() => { if (jQuery("div.self-register-alert").hasClass("show")) { toggleSelfRegAlert(); }  }, 4000);
            }).catch(err => {
                _.delay(() => { if (jQuery("div.self-register-alert").hasClass("show")) { toggleSelfRegAlert(); }  }, 4000);
            });
        }
    }

    const _closeModal = (e) => {
        e.preventDefault();
        setState({id_login_user_admin: data.id_login_user_admin});
        setErr({});
        toggleSelfRegAlert();
        closeModal(e);
    }    

    return (
        <Fragment>
            <div className="text-black px-3 pb-3 pt-3 sign-in-container">
                {/***<h1 className="display-4 mb-2 font-weight-bold h1-title">
                    Self Registration
                </h1>
                <p className="font-size-lg mb-0 text-black-50 p-subtitle">
                    Fill in the fields below and you'll be good to go.
                </p>                
                <div className="divider my-1" />***/}
                <Form onSubmit={_handleSubmit} className="form-customise">
                    <FormGroup>
                        <label style={{color:'#9b9797'}}>Organization Name</label>
                        <p className='h1-title' style={{fontWeight: '700'}}>{_.has(data, 'org_name') ? data.org_name : '-'}</p>
                    </FormGroup>
                    <FormGroup>
                        <label style={{color:'#9b9797'}}>Name</label>
                        <Input placeholder="Enter your name" bsSize="sm" invalid={err.name} type="text" onChange={e => _handleChange(e.target.value, 'name')} value={_.has(state, 'name') ? state.name : ''} />
                    </FormGroup>
                    <FormGroup>
                        <label style={{color:'#9b9797'}}>Email Id</label>
                        <Input placeholder="Enter your email" bsSize="sm" invalid={err.email_id} type="text" onChange={e => _handleChange(e.target.value, 'email_id')} value={_.has(state, 'email_id') ? state.email_id : ''} />
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Col>
                            <label style={{color:'#9b9797'}}>Password (Min. 8 char)</label>
                            <Input placeholder="Enter password" bsSize="sm" invalid={err.pwd} type="password" autoComplete="off" onChange={e => _handleChange(e.target.value, 'pwd')} value={_.has(state, 'pwd') ? state.pwd : ''} />
                        </Col>
                        <Col>
                            <label style={{color:'#9b9797'}}>Confirm Password</label>
                            <Input placeholder="Enter Confirm password" bsSize="sm" invalid={err.confirm_pwd} autoComplete="off" type="password" onChange={e => _handleChange(e.target.value, 'confirm_pwd')} value={_.has(state, 'confirm_pwd') ? state.confirm_pwd : ''} />
                        </Col>
                        <Col xs={12}>
                        {resMsg?.msg && <small className='text-danger'>{resMsg?.msg}</small> }
                        </Col>
                    </FormGroup>
                    <Row>
                        {selfReg.loading ? (
                             <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <Fragment>
                                <Col>
                                    <Button onClick={_closeModal} className=" form-reset-cancel-btn" size="lg" color="warning" block={true}>
                                        <span className="btn-wrapper--label">Close</span>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="submit" size="lg" block={true} color="second" className="form-submit-btn"><span className="btn-wrapper--label">Submit</span></Button>
                                </Col>
                            </Fragment>
                        )}                        
                    </Row>                     
                </Form>
                {selfReg?.msg && 
                <Alert color={selfReg.error ? "danger" : "success"} isOpen={selfRegResAlert} className="mt-4 self-register-alert general-text" toggle={toggleSelfRegAlert}>
                    {selfReg.msg}
                </Alert>}
            </div>
        </Fragment>
    );
}

function UserForgetPasswordForm(props) { 

    const { submitForgetPassword, closeModal, forgetPwd, forgetPwdAlert, forgetPwdToggleAlert } = props;
    const [err, setErr] = useState(false);
    const [visible, setVisible] = useState(false);
    const [state, setState] = useState({});

    /***useEffect(() => {
        if (forgetPwd.loading == false && visible == false && (forgetPwd.error == true || !_.isEmpty(forgetPwd.msg))) { 
            setVisible(!visible);
        }
    }, [forgetPwd]);***/

    const onDismiss = () => {
        //setVisible(!visible);        
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        if (_.isNil(state.email_id) || (_.has(state, 'email_id') && _.isEmpty(state.email_id))) {
            setErr(true);
        } else if (!isValidEmail(state.email_id)) {
            setErr(true);
        } else {
            submitForgetPassword(state);    
        }        
    }

    const _handleChange = (val, name) => {
        setErr(false);
        setState({[name]: val});
    }

    const _closeModal = (e) => {
        e.preventDefault();
        setState({});
        setErr(false);
        closeModal(e);
    }

    return (
        <Fragment>
            <div className="text-black px-3 pb-3 pt-4 sign-in-container">
                {/***<h1 className="display-4 mb-2 font-weight-bold h1-title">
                    Forget Password
                </h1>***/}
                <p className="font-size-lg mb-3 text-black-50 p-subtitle">
                    Please enter your email id below, you will receive the new password
                </p>                
                <div className="divider my-3" />
                <Form onSubmit={_handleSubmit}>
                    <FormGroup className="mb-4">
                        <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={['far', 'envelope']} />
                                </span>
                            </div>
                            <Input placeholder="yourname@yourmail.com" invalid={err} type="email" onChange={(e) => {_handleChange(e.target.value, 'email_id'); }} value={_.has(state, 'email_id') ? state.email_id : ''} />
                        </div>
                    </FormGroup>
                    <Row>
                        {forgetPwd.loading ? (
                             <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <Fragment>
                                <Col>
                                    <Button onClick={_closeModal} size="lg" color="warning" block={true}>
                                        <span className="btn-wrapper--label">Close</span>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="submit" size="lg" block={true} color="second"><span className="btn-wrapper--label">Submit</span></Button>
                                </Col>
                            </Fragment>
                        )}                        
                    </Row>                    
                </Form>
                <Alert color={forgetPwd.error ? "danger" : "success"} isOpen={forgetPwdAlert} className="mt-4 general-text" toggle={forgetPwdToggleAlert}>
                    {forgetPwd.msg}
                </Alert>
            </div>
        </Fragment>
    );
}