export const GET_PROJECTS_LIST_REQUEST_INIT = 'GET_PROJECTS_LIST_REQUEST_INIT';
export const GET_PROJECTS_LIST_REQUEST_DONE = 'GET_PROJECTS_LIST_REQUEST_DONE';
export const GET_PROJECTS_LIST_REQUEST_FAILURE = 'GET_PROJECTS_LIST_REQUEST_FAILURE';

export const GET_MY_PROJECTS_LIST_REQUEST_INIT = 'GET_MY_PROJECTS_LIST_REQUEST_INIT';
export const GET_MY_PROJECTS_LIST_REQUEST_DONE = 'GET_MY_PROJECTS_LIST_REQUEST_DONE';
export const GET_MY_PROJECTS_LIST_REQUEST_FAILURE = 'GET_MY_PROJECTS_LIST_REQUEST_FAILURE';

export const GET_MY_PROJECTS_QUERY_REQUEST_INIT = "GET_MY_PROJECTS_QUERY_REQUEST_INIT";
export const GET_MY_PROJECTS_QUERY_REQUEST_DONE = 'GET_MY_PROJECTS_QUERY_REQUEST_DONE';
export const GET_MY_PROJECTS_QUERY_REQUEST_FAILURE = 'GET_MY_PROJECTS_QUERY_REQUEST_FAILURE';

export const LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT = 'LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT';
export const LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE = 'LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE';
export const LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE = 'LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE';

export const SAVE_PROJECTS_DATA_REQUEST_INIT = 'SAVE_PROJECTS_DATA_REQUEST_INIT';
export const SAVE_PROJECTS_DATA_REQUEST_DONE = 'SAVE_PROJECTS_DATA_REQUEST_DONE';
export const SAVE_PROJECTS_DATA_REQUEST_FAILURE = 'SAVE_PROJECTS_DATA_REQUEST_FAILURE';

export const DELETE_PROJECTS_DATA_REQUEST_INIT = 'DELETE_PROJECTS_DATA_REQUEST_INIT';
export const DELETE_PROJECTS_DATA_REQUEST_DONE = 'DELETE_PROJECTS_DATA_REQUEST_DONE';
export const DELETE_PROJECTS_DATA_REQUEST_FAILURE = 'DELETE_PROJECTS_DATA_REQUEST_FAILURE';

export const TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT = 'TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT';
export const TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE = 'TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE';
export const TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE = 'TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE';

export const GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT = 'GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT';
export const GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE = 'GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE';
export const GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE = 'GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE';

export const GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT = 'GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT';
export const GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE = 'GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE';
export const GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE = 'GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE';

export const GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT = 'GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT';
export const GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE = 'GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE';
export const GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE = 'GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE';

export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT';
export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE';
export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE';

export const DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT = 'DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT';
export const DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE = 'DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE';
export const DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE = 'DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE';

export const SET_ROAD_PROJECT_PAGE_LIST_PAGINATION_DATA_FOR_LSTORE = 'SET_ROAD_PROJECT_PAGE_LIST_PAGINATION_DATA_FOR_LSTORE';
export const SET_ROAD_PROJECT_DATA_FROM_PLIST_FOR_LSTORE = 'SET_ROAD_PROJECT_DATA_FROM_PLIST_FOR_LSTORE';

export const PROJECT_STATUS_UNDER_BIDDING = 4;
export const PROJECT_MODE = { PPP: 1, EPC: 2, HAM: 3 };
export const ROAD_NH_SH_OPTIONS = { 1: 'State Highways', 2: 'National Highway', 3: "All"};
export const QRY_PARAM_LABEL = {
    'nh_sh': 'NH / SH',
    'road_project_name': 'Stretch',
    'm_project_status_id': 'Status',
    'm_state_id': 'State',
    'm_district_id': 'District',
    "road_package_name":'Package',
     "road_stretch_name":'Stretch Name',
    'm_mode_id': 'Mode',
    'm_mode_ppp_id': 'Mode Of PPP',
    'm_company_group_id': 'Group',
    'm_company_id_parent': 'Developer',
    'm_company_id': 'SPV Name',
    'cons_start_date': 'Construction Start',
    'actual_comp_date': 'Actual Completion',
    'pcod_date': 'PCOD',
    'award_date': 'Letter Of Award',
    'date_rfp': 'RFP',
    'date_rfq': 'RFQ',
    'concession_period': 'Concession Period (Years)',
    'const_period': 'Construction Period (Months)',
    'm_basis_award_id': 'Basis Of Award',
    'm_bank_id': 'Bank',
    'tpc': 'Project Cost (Rs.Cr)',
    'stretch_length':"stretch (KM)",
    'dpc': 'Developer Cost (Rs.Cr)',
    'tpc_per_km': 'Project Cost/KM',
    'debt': 'Debt (Rs.Cr)',
    'amount_annuity': 'Annuity (Rs.Cr)',
    'amount_grant': 'Grant (Rs.Cr)',
    'negative_grant': 'Negative Grant (Rs.Cr)',
    'premium': 'Premium (Rs.Cr)',
    'long_term_loan': 'Long Term Loan (Rs.Cr)',
    'equity': 'Equity',
    'operator': 'Operator',
    'updated_interval':'Updated Interval',
};