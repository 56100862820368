import { ApiWs, Request, Server } from "utils";
import _ from 'lodash';
import { GET_WS_LIST_DATA_REQUEST_INIT, GET_WS_LIST_DATA_REQUEST_FAILURE, GET_WS_LIST_DATA_REQUEST_DONE,
    DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE,
    GET_WS_LIST_INIT_DATA_REQUEST_INIT, GET_WS_LIST_INIT_DATA_REQUEST_DONE, GET_WS_LIST_INIT_DATA_REQUEST_FAILURE,
    LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT,LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE,LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE,
    SAVE_WS_PROJECTS_DATA_REQUEST_INIT,SAVE_WS_PROJECTS_DATA_REQUEST_DONE,SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE,
    DELETE_WS_PROJECTS_DATA_REQUEST_INIT,DELETE_WS_PROJECTS_DATA_REQUEST_DONE,DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE,
    GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT, GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE,
    GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT,GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE,GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT_WS, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE_WS, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE_WS,
    TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT,TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE,TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE} from "./../constants";
import moment from "moment";
import { dateFormat } from "highcharts";

export const fnWaterSanitationActions = {
    getWaterSanitationListPageInitData(data = {}) {
        return dispatch => {
            dispatch({ type: GET_WS_LIST_INIT_DATA_REQUEST_INIT });
            return Request.post('getWaterSanitationListPageInitData', data).then(res => {
                dispatch({ type: GET_WS_LIST_INIT_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({ type: GET_WS_LIST_INIT_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err.toString());
            });
        }
    },
    //getAllWaterSanitationProjectsList
    //getWsProjectsList
    getAllProjectsList(data) {
        return dispatch => {
            dispatch({ type: GET_WS_LIST_DATA_REQUEST_INIT });
            return Request.post('getAllWaterSanitationProjectsList', data).then(res => {
                console.log(res)
                dispatch({ type: GET_WS_LIST_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                console.log(err)
                dispatch({ type: GET_WS_LIST_DATA_REQUEST_FAILURE, payload: err.toString() });
                return Promise.reject(err.toString());

            });
        }
    },
    downloadExcelFileData(params = {}, filename = '') {
        return (dispatch) => {
            dispatch({ type: DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT });
            return Request.downloadFiles({
                requestFor: 'exportWaterSanitationExcelData',
                data: params,
                filename: filename || `ws_data_${moment().format('DD_MMM_YYYY')}_${moment.now()}.xls`
            }).then(res => {
                dispatch({ type: DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE });
            }).catch(err => {
                dispatch({ type: DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE, payload: err.toString() });
            });
        }
    },
    loadAddProjectInitData(data = {}) {
        return (dispatch) => {
            dispatch({type: LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('loadAddProjectInitData', data, Server.WSAPI_API).then((res) => {
                dispatch({type: LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    saveDataOfProjectDetails(data) {
        return (dispatch) => {
            dispatch({type: SAVE_WS_PROJECTS_DATA_REQUEST_INIT});
            return Request.post('saveDataOfProjectDetails', data, Server.WSAPI_API).then(res => {
                dispatch({type: SAVE_WS_PROJECTS_DATA_REQUEST_DONE, payload: res});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getProjectDataById(id_ws_project) {
        return (dispatch) => {
            dispatch({type: GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT});
            return Request.post('getProjectDataById', {id_ws_project}, Server.WSAPI_API).then((res) => {
                dispatch({type: GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getWsProjectDetailsLog(id_ws_project) {
        return (dispatch) => {
            dispatch({type: GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT});
            return Request.post('getWsProjectDetailsLog', {id_ws_project}, Server.WSAPI_API).then((res) => {
                dispatch({type: GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getTimeOverRunCalc(data) {
        return (dispatch) => {
            dispatch({type: TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT});
            return Request.post('getTimeOverRunCalc', data).then(res => {
                dispatch({type: TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE, payload: res.meta.data});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    deleteProjectData(data) {
        return (dispatch) => {
            dispatch({type: DELETE_WS_PROJECTS_DATA_REQUEST_INIT});
            return Request.post('deleteProjectData', data, Server.WSAPI_API).then(res => {
                dispatch({type: DELETE_WS_PROJECTS_DATA_REQUEST_DONE, payload: res});
                return Promise.resolve(res);
            }).catch(err => {
                dispatch({type: DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    // getRoadProjectDetailsLog(id_road_project) {
    //     return (dispatch) => {
    //         dispatch({type: GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT});
    //         return Request.post('getRoadProjectDetailsLog', {id_road_project}, Server.WSAPI_API).then((res) => {
    //             dispatch({type: GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE, payload: _.get(res, 'meta.data')});
    //             return Promise.resolve(res);
    //         }).catch(err => {
    //             dispatch({type: GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE, payload: err});
    //             return Promise.reject(err);
    //         });
    //     }
    // },
    getQryPageInitializeData() {
        return (dispatch) => {
            dispatch({ type: GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT});
            return Request.post('getQryPageInitializeData', {}, Server.WSAPI_API).then((res) => {
                dispatch({type: GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({type: GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    },
    getQrySearchResults(data) {
        return (dispatch) => {
            dispatch({ type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT_WS});
            return Request.post('getAllWaterSanitationProjectsList', data).then((res) => {
                dispatch({type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE_WS, payload: _.get(res, 'meta.data')});
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {
                dispatch({type: GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE_WS, payload: err});
                return Promise.reject(err);
            });
        }
    },
};

